import React from 'react';
const Commoncard = (props) => {
    return (
        <>
                    <div class="card-body">
                        <div class="text-center">
                            <img src={props.imgsrc} alt={props.image} className='img-fluid col-md-4 col-4 mx-auto' />
                            <h5 class="my-3 text-uppercase">{props.title}</h5>
                            <p className='col-md-10 col-10 mx-auto'>{props.desc}</p>
                        </div>
                    </div>

        </>
    );
}

export default Commoncard;
