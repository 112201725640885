import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import clientlogo from '../images/Color_Bar.jpg'
import 'swiper/css';
const Testimonial = () => {
  return (
    <>
     <div className="testimonial py-md-5 py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12 text-dark">
                            <h1 className='main_heading'>Client Testimonials</h1>
                        </div>
                        <Swiper
  
                    slidesPerView={1} 
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                    <SwiperSlide>
                        <div className="row">
                            <div className="col-md-8 py-5">
                            <blockquote>
                    I deeply appreciate the professionalism and dedication shown by the Assisto team in deploying and customisation of the IAssist as per requirements.</blockquote>
                    <div className='arrow_div'></div>
                    <p>
                   <img src={clientlogo} alt="client" className='col-md-2 col-3' />
                    </p>
                            </div>
                        </div>
                   
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="row">
                            <div className="col-md-8 py-5">
                            <blockquote>
                    I deeply appreciate the professionalism and dedication shown by the Assist team in deploying and customisation of the IAssist as per requirements.</blockquote>
                    <div className='arrow_div'></div>
                    <p>
                   <img src={clientlogo} alt="client" className='col-md-2 col-3' />
                    </p>
                            </div>
                        </div>
                   
                    </SwiperSlide>
                    </Swiper>
                    </div>
                   
                </div>
            </div>
   

    </>
  );
}

export default Testimonial;
