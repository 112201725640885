import React from 'react';

const Commonbox = (props) => {
  return (
    <>
      <div className="row py-5">
        <div className="col-md-4">
          <div className="box_common text-center">
            <h1 className='title_box py-3'>{props.value1}</h1>
            <p>{props.text1}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box_common text-center">
            <h1 className='title_box py-3'>{props.value2}</h1>
            <p>{props.text2}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box_common text-center">
            <h1 className='title_box py-3'>{props.value3}</h1>
            <p>{props.text3}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Commonbox;
