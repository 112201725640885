import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/Assisto_Right_Image1.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'

const Telecommunication = () => {
  return (
    <>
      <Headerin />
      <div className="Telecommunication_area">
        <Internalcommon title="Telecom" desc="Empowering & Humanizing the Telecom Operations with Conversational AI-iAssist" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Telecom" />
      <Internalsection title="iAssist : Telecom" span_heading="An unprecedented, industry-leading AI/ML based approach" para1="to automating end to end telecom services with robust NLP & Smart Analytics to retail customers while reducing L1 & L2 resource costs, hence increasing the RoI of the Telecom Operators." para2="The explainable AI platform and machine learning capabilities are embedded in the iAssist Platform and available either through an easy-to-use interface or through APIs delivered on-premise, in the cloud or as a SaaS offering." para3="iAssist is compliant to GDPR regulations & strictly adheres to the policies for handling of personal data of users.
" imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Telecom.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="30%" value2="80%" value3="50%" text1="reduction in customer service costs can be achieved by deploying IVAs and automating support functions" text2="of routine questions can be answere by IVAs, freeing up agents for more productive work" text3="decrease in call volume can be achieved by implementing VAs across multiple touchpoints" />
      </div>
  
          <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Realtime Tech Support & Customer Service " 
          list1="- Conversational AI" list2="- ASR" list3="- Voice Authentication" list4="- Face recognition" 
          casepara1="&bull; When user calls, ASR will verify voice profile of the customer to authenticate user" 
          casepara2="&bull; Over the web channel, face recognition will be used for customer verification"
          casepara3="&bull; Post verification we can process customer request"
          title2="Reset Passwords " 
          list11="- Conversational AI" list12="- ASR" list13="- Voice Authentication" list14="- Face recognition" 
          casepara11="&bull; When user calls, ASR will verify voice profile of the customer to authenticate user "
          casepara12="&bull; Over the web channel, face recognition will be used for customer verification"
          casepara13="&bull; post verification, we can reset the password "
          img2={img2}

          img3={img3} 
          title12="Know about tariffs " 
          list21="- Conversational AI" list22="- ASR" list23="- Voice Authentication" list24="- Face recognition" 
          casepara21="&bull; To recognize the user, use voice authentication or face recognition or combination along with details." 
          casepara22="&bull; Based on profile / policies, prompt to the user various schemes or products or offers applicable "
          />
           <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Internet Configuration 
</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                 
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <ul className='list-unstyled'>
                <li>&bull; Step by step guide including combination of image and text explaining process to be followed for configuring or troubleshooting steps</li>
               </ul>

               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     <div className="row py-5">
     <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Complaint Analysis</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                   <li>- Instant Analytics</li>
                  
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <ul className='list-unstyled'>
                <li>&bull; Take key parameters from users and connect to database.</li>
                <li>&bull; Render data in tabular or chart format for different category of complaints if data through API of a tool</li>
                <li>&bull; In case of database, use NL2SQL to filter and fetch data to be rendered in tabular or chart format</li>
               </ul>
              
               </div>
       </div>
       </div>
       
     </div>
     </div>
    
      <Consultblock />

      <Footer />

    </>
  );
}

export default Telecommunication;
