import React from 'react'
import logo from '../images/assisto.png'
import { NavLink, Link} from 'react-router-dom'

function Footer() {
  return (
    <>
      <div className="footer_area  py-5 text-white">
        <div className="container">
          <div className="row pt-4 px-5">
          <div className="col-md-2 col-xl-3 col-6">
            <img src={logo} alt="logo" className="img-fluid col-8" />
            <p className="text-Capitalize py-3 text-white">All Right Reserved. Assisto</p>
          </div>
          <div className="col-md-3 col-xl-3 col-6">
            <h4>Quick Links</h4>
            <ul className="list-unstyled">
              <li><NavLink to="#" className="text-white py-1 d-block text-decoration-none">About Us</NavLink></li>
              <li><Link to={{ pathname: "https://assisto.tech/blog" }} target="_blank"  className="text-white py-1 d-block text-decoration-none" >Blog</Link></li>
              <li><NavLink to="/Contact" className="text-white py-1 d-block text-decoration-none">Contact Us</NavLink></li>
              <li><NavLink to="#" className="text-white py-1 d-block text-decoration-none">FAQ</NavLink></li>
              <li><NavLink to="#" className="text-white py-1 d-block text-decoration-none">Career</NavLink></li>
            </ul>
          </div>
          <div className="col-md-3 col-xl-3 col-6">
          <h4>Legal Stuff</h4>
            <ul className="list-unstyled">
              <li><NavLink to="/Disclaimer" className="text-white py-1 d-block text-decoration-none">Disclaimer</NavLink></li>
              <li><NavLink to="/Financing" className="text-white py-1 d-block text-decoration-none">Financing</NavLink></li>
              <li><NavLink to="/assisto_security_policy.pdf" target="_blank" download className="text-white py-1 d-block text-decoration-none">Privacy Policy</NavLink></li>
              <li><NavLink to="/Termsofservice" className="text-white py-1 d-block text-decoration-none">Terms of Services</NavLink></li>
            </ul>
          </div>
          <div className="col-md-4 col-xl-3 col-6">
            <h4 className="pt-md-4 pe-3 ">Knowing You'r always on the best deals.</h4>

            <ul className="social_icons list-unstyled d-flex">
            <li><NavLink  to="#" className="text-white py-1 d-block text-decoration-none"> <i class="bi bi-twitter text-white" style={{ fontSize: 20 }}></i></NavLink></li>
            <li><NavLink to="#" className="text-white py-1 px-3 d-block text-decoration-none"> <i class="bi bi-facebook text-white" style={{ fontSize: 20 }}></i></NavLink></li>
              <li><NavLink  to="#" className="text-white py-1  d-block text-decoration-none"> <i class="bi bi-instagram text-white" style={{ fontSize: 20 }}></i></NavLink></li>
            </ul>
           
          </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
