import React,{useState} from 'react';
import data from '../components/Industypopdata';
import Modaltest from '../components/Modeltest';
const Testpopfinal = () => {
    const[model, setModel]=useState(false);
    const[tempData, setTemData] =useState([])
    const getData=(img, title, desc, img1, internal,external,percent, download)=>{
        let temData=[img, title, desc, img1, internal, external, percent, download];
        setTemData(item=>[1, ...temData]);
        return setModel(true);
    }
  return (
    <>
     
      <div className="row">
                        <div className="col-md-8 col-12">
                            <div className="row pt-5 gx-md-5 gx-1">

                                {
                                   data.cardData.map((item, index)=>{
                                    return(
                                        <div className="col-md-3 col-xxl-3 col-sm-3 col-6 text-left" key={index}>
                                        <div className="box text-center">
                                        <button className='btn btn-primary' onClick={()=>getData(item.imgsrc, item.title, item.desc, item.imgsrc1, item.internal, item.external, item.percent, item.download )}>
                                       <img src={item.imgsrc} alt="img"  className="img-fluid col-md-6 col-10 mx-auto"/>
                                       <h4 className='py-4 text-capitalize text-center text-white'>{item.title}</h4>
                                       </button>
                                       </div>
                                      </div>
                                    )
                                   }
                                   )
                                }
                            </div>
                        </div>
                      
                    </div>
                    {
                       model === true ?  <Modaltest img={tempData[1]} title={tempData[2]} desc={tempData[3]} img1={tempData[4]} internal={tempData[5]} external={tempData[6]} percent={tempData[7]} download={tempData[8]}  hide={()=>setModel(false)} /> : ''
                    }

                  
    </>
  );
}

export default Testpopfinal;
