import icon1 from '../images/NLP_Icon.png'
import icon2 from '../images/All_Automatically_Icon.png'
import icon3 from '../images/Conventiational_Engine_Icon.png'
import icon4 from '../images/Complex_Query_Icon.png'
import icon5 from '../images/Multi_Language_Icon.png'
import icon6 from '../images/Analytics_Engine_Icon.png'
import icon7 from '../images/VoiceBot_Icon_01.png'
import icon8 from '../images/Workflow_Icon.png'


const Sdata=[
   
    {
        iconimg:icon1,
        title:"NLP",
    },
    {
        iconimg:icon2,
        title:"Learns Automatically",
    },
    {
        iconimg:icon3,
        title:"Conversation Engine",
    },
    {
        iconimg:icon4,
        title:"Complex Query",
    },
    {
        iconimg:icon5,
        title:"Multi-Language Support",
    },
    {
        iconimg:icon6,
        title:"Analytics Engine",
    },
    {
        iconimg:icon7,
        title:"Voice Bots",
    },
    
    {
        iconimg:icon8,
        title:"Workflow",
    }
    

]

export default Sdata;