import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/Logistic_Rigth_img.png'
import Commonbox from '../../components/Commonbox';

import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const Logistics = () => {
  return (
    <>
      <Headerin />
      <div className="Logistics_area">
        <Internalcommon title="Logistics" desc="Conversational AI for Logistics & Transportation" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Logistics" />
      <Internalsection title="iAssist : Logistics" span_heading="Logistics is a sector that is ripe and ready for digitisation" para1="Especially with the amount of data that is being generated across the network. According to McKinsey, the logistics industry can generate up to $2 trillion every year in economic value simply by adopting conversational AI." para2="Companies are still dependent on legacy solutions that aren't equipped to handle today's real-world supply chain and logistics operations." para3="The idea behind conversational AI in logistics is to automate repetitive processes and attempt to eliminate human errors. It entails connecting businesses to their customers through the medium of conversational messaging & can make the whole supply chain experience seamless
" imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Logistic.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="90%" value2="57%" value3="$7.3B" text1="of interactions in banks will be automated by 2022 using chatbots." 
      text2="of consumers are interested in chatbots for instantaneous responses." text3="of the operational cost will be saved using chatbots in the banking sector by 2023." />      </div>
  
  <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Automated Warehouse
</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                  
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <p>Connecting the Inventory management API to chatbot helps in:

</p>
<ul className='list-unstyled'>
  <li>&bull; Procurement optimization</li>
  <li>&bull; Operations Automation</li>
  <li>&bull; Planning teams</li>
  <li>&bull; Stock management and critical level notification</li>
</ul>
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     </div>
     <div className="container">
            <div className="row py-5">
            <div className="col-md-4">
       <img src={img2} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Analytics & Planning

</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                  <li>- Instant Analytics</li>
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
      
<ul className='list-unstyled'>
  <li>&bull; Take key parameters from users and connect to database; render data in tabular or chart format if data is through API of a tool
</li>
  <li>&bull; In case, demand data and inventory utilization is in database, use NL2SQL to filter and fetch data to be rendered in tabular or chart format. Predict ordering timelines. Helps in JIT
</li>
  
</ul>
               </div>
       </div>
       </div>
     
     </div>
     </div>
     <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Sales & Marketing
</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                  <li>- Instant Analytics</li>
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <ul>
                <li>Using NL2SQL we can fetch relevant dataset and feed it into conversational AI for further filtering analysis and charting for prediction to simplify:</li>

<ul className='list-unstyled'>
  <li>&bull; Business data analytics
</li>
  <li>&bull; Marketing analytics
</li>
 
</ul>
<li>API Driven conversation to fetch data
</li>
               </ul>
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img3} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     </div>
     <div className="container">
            <div className="row py-5">
            <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Back Office Automation

</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                  <li>- Instant Analytics</li>
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
      <ul>
        <li>Accept User inputs/ documents in real time through conversational AI
</li>
        <li>Extract information from documents through RPA
</li>
        <li>RPA Bot performs some or all of the below functions:</li>
      
<ul className='list-unstyled'>
  <li>&bull; Take key parameters from users and connect to database; render data in tabular or chart format if data is through API of a tool
</li>
  <li>&bull; In case, demand data and inventory utilization is in database, use NL2SQL to filter and fetch data to be rendered in tabular or chart format. Predict ordering timelines. Helps in JIT
</li>
  
</ul>
</ul>
               </div>
       </div>
       </div>
     
     </div>
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default Logistics;
