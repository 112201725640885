import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';

import serviceimg from '../../images/aviation/side.png'

import Commonsolution from '../../components/Commonsolution';

const Whatwedo = () => {
  return (
    <>
   <Headerin />
   <div className="whatwedo_area">
       <Internalcommon  title="What We Do" desc="Empowering & Humanizing the Telecom Operations with Conversational AI-iAssist" imgsrc={serviceimg}/>
   </div>
   <Breadcrumsec  maintitle="Home" subtitle="WHAT WE DO" />
 
   <div className="container pt-4">
                    <div className="row ">
                                                <Commonsolution/>
                                                </div>
                                                </div>
   <Consultblock/>
  
   <Footer />
      
    </>
  );
}

export default Whatwedo;
