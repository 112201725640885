import React from 'react'
// Import Swiper React components
import backgroundbg from "../images/Banner.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Commonslider from './Commonslider';

function Sliderhome() {
  return (
    <div>
<div
  style={{
    backgroundImage: `url(${backgroundbg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right"
  }}
>
  <div className="container pt-5" id="slider_area">
    <div className="row">
      <div className="col-md-12  mt-5 pt-5">
      <Swiper
       modules={[Navigation, Pagination, Scrollbar]}
      spaceBetween={50}
      slidesPerView={1} 
      pagination={{ clickable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
     <Commonslider title="Meet iAssist" 
       subtitle="A unified AI enabled NLP framework"  btn_link="Explore More" />
      </SwiperSlide>
      {/* <SwiperSlide>
     <Commonslider title="Meet2 iAssist" 
       subtitle="A unified AI enabled NLP framework"  btn_link="Explore More" />
      </SwiperSlide>
      <SwiperSlide>
     <Commonslider title="Meet3 iAssist" 
       subtitle="A unified AI enabled NLP framework"  btn_link="Explore More" />
      </SwiperSlide>
      <SwiperSlide>
     <Commonslider title="Meet4 iAssist" 
       subtitle="A unified AI enabled NLP framework"  btn_link="Explore More" />
      </SwiperSlide> */}
     
    </Swiper>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Sliderhome
