import React from 'react';

const Solutionboxcommon = (props) => {
  return (
    <>
        <div className="row align-items-center pb-5">
      
      <div className="col-md-6">
       <h2 className='fw-600'>{props.title}</h2>
       <p className='py-2'>{props.para}</p>
      </div>
      <div className="col-md-6">
        <img src={props.imgsrc} alt="img" className='img-fluid' />
      </div>
      </div>
    </>
  );
}

export default Solutionboxcommon;
