import React from 'react';
import Testpopfinal from '../pages/Testpopfinal';

const industryapp = () => {
    return (
        <>
            <div className="industyapp_area  py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12 text-white">
                            <h1 className='main_heading'>Industrial Applications & Solutions</h1>
                            <h4 className='sub_heading'>With flexible architecture but robust integration framework, we can cater to a wide set of external & internal use cases for various industries</h4>
                        </div>
                    </div>
                   
                    <Testpopfinal/>
                </div>
            </div>
        </>
    );
}

export default industryapp;