
import voicecase1 from '../images/instantbi/Data_Source_Agnostic.png'
import voicecase2 from '../images/instantbi/easy_to_configure_and_deploy.png'
import voicecase3 from '../images/instantbi/faster_analytics_of_data.png'
import voicecase4 from '../images/instantbi/Make_dashboard_smarter.png'
import voicecase5 from '../images/instantbi/Quick_Custom_report.png'
import voicecase6 from '../images/instantbi/Smart_AI_Chating.png'
import voicecase7 from '../images/instantbi/Talk_to_your_database.png'
import voicecase8 from '../images/instantbi/Text to SQL.png'



const Instantcasedata=[

  
    {
        voicecaseimg:voicecase1,
        voicecasetitle:"Data Source Agnostic",
    },
    {
        voicecaseimg:voicecase2,
        voicecasetitle:"Easy to configure and deploy",
    },
    {
        voicecaseimg:voicecase3,
        voicecasetitle:"faster analytics of data",
    },
    {
        voicecaseimg:voicecase4,
        voicecasetitle:"Make dashboard smarter",
    },
    {
        voicecaseimg:voicecase5,
        voicecasetitle:"Quick Custom report",
    },
    {
        voicecaseimg:voicecase6,
        voicecasetitle:"Smart AI Chating",
    },
    {
        voicecaseimg:voicecase7,
        voicecasetitle:"Talk to your database",
    },
    {
        voicecaseimg:voicecase8,
        voicecasetitle:"Text to SQL",
    },
  

 
    

]

export default Instantcasedata;