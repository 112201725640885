import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/Data_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'

const DataCentres = () => {
  return (
    <>
      <Headerin />
      <div className="DataCentres_area">
        <Internalcommon title="Data Centers" desc="Conversational AI at Data Centres" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Data Centers" />
      <Internalsection title="iAssist : Data Centers" span_heading="There are few aspects of today’s world that aren’t being touched by artificial intelligence and machine learning." 
      para1="Automated systems can be used to predict faults and respond to capacity needs, and are paving the way for the era of the ‘lights out’ data centre." 
      para2="At the moment there’s a lot of data collected at the Data Centres that’s not always well utilized that we can extract more benefit from. It is an instant analytics tool for any dashboard to perform various interactive actions to understand data more intuitively on ad-hoc basis and help with faster & better informed decisions." 
 imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_DataCentre.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="60%" value2="40%" value3="30%"   text1="faster decision making" 
      text2="cost savings" text3="better customer service" />
      </div>
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Governance " 
          list1="- Conversational AI" 
          casepara1="&bull; Gather data points required by third party tools from users for" 
          casepara2="&bull; Governance
          Inter departmental Process coordination Process monitoring
          Process control
          "
          
          title2="Report Analysis" 
          list11="- Conversational AI" list12="- Instant Analytics" 
          casepara11="&bull; Using NL2SQL we can fetch relevant dataset and feed it into conversational AI for further filtering analysis and charting for prediction to simplify:
          "
          casepara12="&bull; Business data analytics
          Incident analytics
          Marketing analytics API Driven conversation to fetch data
          "
        
          img2={img2}

          img3={img3} 
          title12="Dashboard Analysis" 
          list21="- Conversational AI" list22="- Instant Analytics" 
          casepara21="&bull; Using NL2SQL we can fetch relevant dataset and feed it into conversational AI for further filtering analysis and charting for prediction to achieve:" 
          casepara22="&bull; Security KPI Analytics
          Correlation of multi device KPI analytics for threat identification
          Product KPI analytics
          Business data analytics API Driven conversation to fetch data"
      
          />
           <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Realtime Tech Support & Customer Service</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                   <li>- ASR</li>
                   <li>- Voice Authentication</li>
                   <li>- Face recognition</li>
                 
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <ul className='list-unstyled'>
                <li>&bull; When user calls, ASR will verify voice profile of the customer to authenticate user</li>
                <li>&bull; Over the web channel, face recognition will be used for customer verification</li>
                <li>&bull; Post verification we can process customer request</li>
                
               </ul>
               <p></p>
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default DataCentres;
