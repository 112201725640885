import client1 from '../images/Client_01.png'
import client2 from '../images/Client_02.png'
import client3 from '../images/Client_03.png'
import client4 from '../images/Client_04.png'
import client5 from '../images/Client_05.png'
// import client6 from '../images/Client_06.png'
// import client7 from '../images/Client_07.png'
// import client8 from '../images/Client_08.png'
// import client9 from '../images/Client_09.png'
// import client10 from '../images/Client_10.png'

const Clientdata=[
   
    {
        iconimg:client1,
        title:"Telecom",
    },
    {
        iconimg:client2,
        title:"Bfsi",
    },
    {
        iconimg:client3,
        title:"NLP3",
    },
    {
        iconimg:client4,
        title:"NLP4",
    },
    {
        iconimg:client5,
        title:"NLP5",
    }
   


]

export default Clientdata;