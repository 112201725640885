import React from 'react'
// import Casestudy from '../components/Casestudy';
import Consultblock from '../components/Consultblock';
import Feature from '../components/Feature';
import Footer from '../components/Footer';
import Framework from '../components/Framework';
import Header from '../components/Header';
import Industryapp from '../components/Industryapp';
import Ourclients from '../components/Ourclients';
import Ourpartner from '../components/Ourpartner';
import Ourservices from '../components/Ourservices';
import Sliderhome from '../components/Sliderhome';
import Testimonial from '../components/Testimonial';

const Home = () => {
  return (
    <>
    <Header/>
    <Sliderhome/>
    <Framework/>
    <Feature />
    <Industryapp />
    <Ourservices/>
    <Ourpartner/>
    <Consultblock />
    <Ourclients />
    <Testimonial/>
    {/* <Casestudy /> */}
    <Footer/>
    </>
  )
}

export default Home
