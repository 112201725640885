import React from 'react';
import article1 from "../images/Resource_01.jpg";
import article2 from "../images/Resource_02.jpg";
import article3 from "../images/Resource_03.jpg";
import article4 from "../images/Resource_04.jpg";
import Headerin from '../components/Headerin';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import Commonresource from '../components/Commonresource';
const Resource = () => {
  return (
    <>
      <Headerin />
      <div className="bg-white  py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-12 text-dark">
              <h1 className='main_heading' >Resouces</h1>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-md-4 mb-5">
            <NavLink to="/resource/partner/assisto_security_policy.pdf" target="_blank" className=" d-block border border-light bg-light p-3 shadow_box text-uppercase  text-primary-500 fw-600 text-decoration-none"  >
              <Commonresource imgsrc={article1} title="Assisto Security Policy"
              />
              Read Full Articles</NavLink>
            </div>
            <div className="col-md-4 mb-5">
            <NavLink to="/resource/partner/march_2022.pdf" target="_blank" className=" d-block  border border-light bg-light p-3 shadow_box text-uppercase  text-uppercase  text-primary-500 fw-600 text-decoration-none"  >
              <Commonresource imgsrc={article2} title="Release Note"
              />
              Read Full Articles</NavLink>
            </div>
            <div className="col-md-4 mb-5">
            <NavLink to="/resource/partner/training_manual.pdf" target="_blank" className=" d-block border border-light bg-light p-3 shadow_box text-uppercase  text-uppercase  text-primary-500 fw-600 text-decoration-none"  >
              <Commonresource imgsrc={article3} title="Training Manual"
              />
              Read Full Articles</NavLink>
            </div>
            <div className="col-md-4 mb-5">
            <NavLink to="/resource/partner/Report Generation with iAssist.pdf" target="_blank" className=" d-block  border border-light bg-light p-3 shadow_box text-uppercase  text-uppercase  text-primary-500 fw-600 text-decoration-none"  >
              <Commonresource imgsrc={article4} title="Report Generation with iAssist"
              />
              Read Full Articles</NavLink>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default Resource

