import React from 'react';
import Footer from '../components/Footer';
import Headerin from '../components/Headerin';
import Internalcommon from '../components/Internalcommon';
import Breadcrumsec from '../components/Breadcrumsec';
import serviceimg from '../images/aviation/side.png'
import voicebotimg from '../images/About_Us_Right.png'
import Internalsection from '../components/Internalsection';
import Aboutmision from '../components/Aboutmision'
import Corevalue from '../components/Corevalue';
const About = () => {
  return (
    <>
       <Headerin />
   <div className="about_area">
       <Internalcommon  title="Assisto" desc="Where Mankind’s Complex Conversations Meet Modern World’s Revolutionary Technologies." imgsrc={serviceimg}/>
   </div>
   <Breadcrumsec  maintitle="Home" subtitle="About Us" />
  
   <Internalsection title="Introduction to US!"  subtitle="Assisto Technologies Pvt. Ltd. is an Indian startup established in Jan 2021 and headquartered in Noida." para1="At Assisto, we are a dynamic technical elite, breaking BOT stereotypes and redefining AI. We specialise in providing sophisticated and exceptional AI driven chatbots and voicebots to help enterprises elevate their services as well as enhance their customer experience." para2="Assisto caters to the needs of every industry, organisation and roles by delivering an easy to train interface with the ability to train with minimal data as well as integrate with various types of database and Enterprise API driven systems." para3="Our brilliant turnkey solution deviates from the norms of AI due to its striking features which include multilingual capabilities, highly accurate spell check, proficient synonym finder, efficient world of revolutionary AI technologies with iAssist’s 4+ different chatbot and voicebot flavours uniquely designed for over 10 different enterprise segments and markets." imgsrc={voicebotimg}/>
   <div className="about_mission pt-5 pb-3">
    <div className="container">
    <Aboutmision title="Our Mission" subheading="You think, We assist" para="Optimal implementation shall facilitate the organisation to
achieve the intended customer support standards. That is,
to interact with customer’s seamlessly and resolve their
issues upto their satisfaction"/>
      </div>
      </div>
      <div className="about_mission pt-3 pb-5">
    <div className="container">
    <Aboutmision title="Our Vision" subheading="You think, We assist" para="Optimal implementation shall facilitate the organisation to
achieve the intended customer support standards. That is,
to interact with customer’s seamlessly and resolve their
issues upto their satisfaction"/>
      </div>
      </div>


<Corevalue/>
<Footer/>
    </>
  );
}

export default About;
