import React from 'react';
import Commoncorevalue from '../components/Commoncorevalue';

const Corevalue = () => {
  return (
    <>
      <div className="  py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12 text-white">
                            <h1 className='main_heading text-dark'>Our Core Values</h1>
                          
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="row pt-5 gx-md-5 gx-1">

                                            <Commoncorevalue   />
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}

export default Corevalue;
