import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/Retail_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const RetaileCommerce = () => {
  return (
    <>
      <Headerin />
      <div className="RetaileCommerce_area">
        <Internalcommon title="Retail & Ecommerce" desc="Automating E-Commerce with Conversational AI" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Retail & Ecommerce" />
      <Internalsection title="iAssist : Retail & Ecommerce" span_heading="Give customers the brand interactions they deserve" 
      para1="With chatbots predicted to manage 85% of customer service interactions, the ecommerce industry is going through a significant shift in terms of customer experience. To stay ahead of the curve, exceptional customer experience is the only way to survive the intensely competitive market. " 
      para2="Conversational AI helps brand build winning relationship with their consumers, obtain deeper analytical insights into the humongous data being captured through various platforms, automate large-scale conversations for better cost savings, greater customer experience and higher sales revenue." 
       imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Retail_Ecommerce.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="30%" value2="35%"  text1="Save on customer support costs" text2="industries have the most popular use cases for voice assistants." 
       />

      </div>
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Order placement & Modification" 
          list1="- Conversational AI" 
          casepara1="&bull; Connecting the E-Store API to chatbot helps in simplifying the process of suggesting to user as carousal and present the offer along with engaging the user on omni channel for quick closure and assisting them order tracking or modification
          " 
       
          title2="Abandoned Carts Analysis" 
          list11="- Conversational AI" list12="- Instant Analytics"
          casepara11="&bull; NL2SQL can fetch relevant dataset and feed it into conversational AI for further filtering analysis and charting for prediction"
          casepara12="&bull; Abandoned Carts Analysis, correlation with user profile along with deals & promotions can be extended to the customer"
         
          img2={img2}

          img3={img3} 
          title12="Tech Support & Customer Service" 
          list21="- Conversational AI" list22="- ASR" list23="- Voice Authentication" list24="- Face recognition"
          casepara21="&bull; Voice based & facial recognition for bio authentication
          " 
          casepara22="&bull; User requests/ queries will be addressed through conversational AI in realtime"
          
          />
      <Consultblock />

      <Footer />

    </>
  );
}

export default RetaileCommerce;
