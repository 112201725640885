import React from 'react';

const Aboutmision = (props) => {
  return (
    <>
   
      <div className="boxabout col-md-8">
      <h1 className='main_heading'>{props.title}</h1>
      <h4 className='sub_heading'>{props.subheading}</h4>
      <p className='p-0 m-0'>{props.para}</p>
      </div>
    
    </>
  );
}

export default Aboutmision;
