import React, { Component } from 'react';
export default class Modaltest extends Component {
    render() {
        let modelStyle = {
            display: 'block',
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: '99999',
        }
        return (
            <>
                <div className="modal show fade modal-dialog-centered" style={modelStyle} >
                    <div className='modal-dialog modal-fullscreen vertical-align-center modal-dialog-centered'>
                        <div className="modal-content border-0 rounded-0">
                            <div className="modal-body p-0 overflow-x-hidden">
                                <div className="modal_box ">
                                    <div className="row">
                                        <div className="col-md-8 p-md-5 p-3 text-white">
                                            <div className="ps-md-5 ps-2">
                                                <span type="button" className="btn-close float-end" onClick={this.props.hide}>Close </span>
                                                <img src={this.props.img} className="img-fluid" width="100px" alt='img' /> <br />
                                                <h1 class=" text-white  my-3">{this.props.title}</h1>
                                                <p className='text-white'>{this.props.desc}</p>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h3>Internal</h3>
                                                        <ul className='listdata'>
                                                            {this.props.internal.map((item, i) => (
                                                                <li key={i}> {item} </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <h3>External</h3>
                                                        <ul className='listdata'>
                                                            {this.props.external.map((item, i) => (
                                                                <li key={i}> {item} </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <h3>Statistics</h3>
                                                <ul className='listdata'>
                                                    {this.props.percent.map((item, i) => (
                                                        <li key={i}> {item} </li>
                                                    ))}
                                                </ul>
                                                {/* <a href={this.props.download} download={this.props.download} className="py-2 btn btn-dark mt-3 text-uppercase">  Click to Download </a> */}
                                                <a href={this.props.download} download={this.props.download} target="_blank" rel="noreferrer" className="py-2 btn btn-dark mt-3 text-uppercase">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                                                        <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
                                                    </svg>
                                                    &nbsp; Click to Download
                                                </a>

                                            </div>
                                        </div>


                                        <div className="col-md-4">
                                            <img src={this.props.img1} alt="side" className='img-fluid' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
