import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';

import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/F&B_Rigth_img.png'
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const FoodBeverage = () => {
  return (
    <>
      <Headerin />
      <div className="FoodBeverage_area">
        <Internalcommon title="Food & Beverage" desc="Conversational AI for F&B" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Food & Beverage" />
      <Internalsection title="Food & Beverage" span_heading="Conversational AI for F&B" 
      para1="India's food and beverage industry is growing at a rapid rate of 14% CAGR with customers seeking better experiences every single time they are eating out or ordering. " 
      para2="AI is helping the organization to analyse and gain a better understanding of variables in the supply chain by anticipating future scenarios; innovate rapidly by reducing the time to market, and by establishing an agile supply chain that is capable of foreseeing and also dealing with the uncertainties." 
       para3="Most large food and beverage companies understand the value of messaging applications for responding to client inquiries. Many small restaurants, on the other hand, have yet to integrate new restaurant technology for customer contact"
       imgsrc={voicebotimg} />
     
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Customer Service" 
          list1="- Conversational AI" 
          casepara1="&bull; Manage Table reservations
          " 
          casepara2="&bull; Take orders right with special requests
          Collect payments and 
          " 
          casepara3="&bull; send receipts
          Collect feedback
          " 
          
          title2="Marketing " 
          list11="- Conversational AI" list12="- Instant Analytics"
          casepara11="&bull; Gather information through conversation with user to:
          "
          casepara12="&bull; Manage Customer Loyalty Rewards & programs"
          casepara13="&bull; In-chat promotions and coupons"
          casepara14="&bull; Simplify the Marketing campaigns over whatsapp, Facebook messenger, Instagram, etc."
          img2={img2}

          img3={img3} 
          title12="Inventory Management" 
          list21="- Conversational AI"  
          casepara21="&bull; Connecting the Inventory management API to chatbot helps in simplifying the process for procurement, operations and planning teams in inturn help in" 
          casepara22="&bull; Procurement optimization
          "
          casepara23="&bull; Waste reduction
          "
          casepara24="&bull; Pricing Analytics"
          />
           <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Data Analysis
</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                   <li>- Instant Analytics</li>
                   
                 
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <ul>
                <li>Using NL2SQL can fetch relevant dataset </li>
                <li> feed it into conversational AI for filtering data for analysis and charting for prediction to simplify:</li>
                <ul className='list-unstyled'>
                <li>&bull; Logistics tracking</li>
                <li>&bull; Sales Cycle projection</li>
                <li>&bull; Customer emotions analytics & preferences</li>
                <li>&bull; Customer grievances for quality control</li>
              </ul>
               </ul>
  
             
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default FoodBeverage;
