import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/oil_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const OilGas = () => {
  return (
    <>
      <Headerin />
      <div className="OilGas_area">
        <Internalcommon title="Oil & Gas" desc="Conversational AI in the domain of Oil & Gas" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Oil & Gas" />
      <Internalsection title="iAssist : Oil & Gas" span_heading="With the dynamic landscape for energy production, AI provides powerful benefits across the entire value chain." 
      para1="The oil and gas industry is beginning to see the incredible impact that AI can have on every sector in the value chain. The opportunities for AI strike directly at the greatest challenges in today’s oilfield." 
      para2="Companies that effectively leverage AI will have a distinct advantage over other operators that lack accurate understanding of their reservoirs, operating processes, and producing assets." 
      para3="In addition, downstream operations can be optimized to minimize costs and maximize spreads."
      imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Oil_Gas.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="45%" value2="30%"  text1="cost reduction Production & Maintenance cost reduction by leveraging AI and Analytics to boost data-driven decision-making" 
      text2="cost reduction In operational by automating almost all the routine tasks, hence reducing human error"  />
      </div>
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Daily Production Analysis" 
          list1="- Conversational AI" list2="- Instant Analytics" 
          casepara1="&bull; Using NL2SQL we can fetch relevant dataset and feed it into conversational AI for further filtering analysis and charting for prediction" 
          
          title2="Troubleshooting Guide" 
          list11="- Conversational AI" 
          casepara11="&bull; Before starting of any crucial activity on Oil Rigs the system engineer can start the focused guide and can have easy reference guide step by step through a combination of image and text"
         
          img2={img2}

          img3={img3} 
          title12="Inventory Management" 
          list21="- Conversational AI"  
          casepara21="&bull; Connecting the Inventory management API to chatbot helps in simplifying the process for procurement, operations and planning teams" 
         
          />
         
          <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">24x7 Helpdesk
</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                  <li>- Voice Bot</li>
                  <li>- Instant Analytics</li>
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
              
<ul className='list-unstyled'>
  <li>&bull; Voice based & facial recognition for bio authentication for users on- field

</li>
  <li>&bull; User requests/ queries will be addressed in real time through conversational AI
</li>
<li>&bull; Escalate the unresolved queries/ complaints as per the defined policies
</li>
 
</ul>

               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default OilGas;
