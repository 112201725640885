import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';

import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import Instantcase from '../../components/Instantcase';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/bi_Banner.png'

const InstantBiAnalatics = () => {
  return (
    <>
   <Headerin />
   <div className="InstantBiAnalatics_are">
       <Internalcommon  title="Instant Analytics/BI" desc="An AI tool for Exploring data to improve organizational processes & business decision making." imgsrc={serviceimg}/>
   </div>
   <Breadcrumsec  maintitle="Home" subtitle="Instant Analytics/BI" />
   <Internalsection title="Simplify Data Discovery " span_heading="and Remove Dependencies to Take Intelligent Decisions" subtitle="Stay on top of all critical business parameters, 24X7
" para1="The fixed KPI dashboard presents challenge as these days business or tools gather huge amount of data and each data point might have relevance in different context hence the future has to move towards Flexible KPI Dashboarding or Instant Analytics." 
 para2="iASSIST is an instant analytics tool for any dashboard to perform various interactive analysis to understand data more intuitively on ad-hoc basis to help faster and better informed decisions. It gives simplified yet flexibility to analyse and present data into more friendly format helping granular understanding of individual or combination of KPI. This helps improving the operational efficiencies of the organisation." 
 para3="Business Intelligence Bot gets granular business insights on-the-go to provide decision-makers critical business metrics instantly and help them make crucial and data backed business decisions in a faster and cost-effective manner" imgsrc={voicebotimg}/>
 <div className="container pb-5">
    <div className="row">
      <div className="col-md-12">
      <h4>Key characteristics:</h4>
<ul className='list_count_new_style'>
  <li>Interpret complex data with utmost Accuracy
</li>
  <li>Find Correlations fast and Easy</li>
  <li>Convert Static KPI to Dynamic KP</li>
  <li>Unified View for Database Analysis</li>
  <li>Seamless Integration with the existing tools and BI Dashboard
</li>
  <li>Custom Reports from Multiple Tools/ application generated instantly
</li>
<li>Tabular Data, Pictorial or Chart Presentation of Data</li>
<li>Automation of Report Cycle & Notifications at custom schedules</li>

</ul>
      </div>
      </div> 
    </div>
    <Instantcase title="Key Features of Instant Analytics/BI" />
   <Consultblock/>

   <Footer />
      
    </>
  );
}

export default InstantBiAnalatics;
