import React from 'react';
import imgsrc1 from '../images/about/intergrity1.png'
import imgsrc2 from '../images/about/diversity2.png'
import imgsrc3 from '../images/about/quality3.png'
import imgsrc4 from '../images/about/team4.png'
import imgsrc5 from '../images/about/innovation5.png'
import imgsrc6 from '../images/about/passion6.png'

const Commoncorevalue = (props) => {
  return (
    <>
       <div className="col-md-4 mb-3">
            <div class="img_contain">
            <img src={imgsrc1} alt={props.image} className='img-fluid' />
            </div>
            </div>
            <div className="col-md-4 mb-3">
            <div class="img_contain">
            <img src={imgsrc2} alt={props.image} className='img-fluid' />
            </div>
            </div>
            <div className="col-md-4 mb-3">
            <div class="img_contain">
            <img src={imgsrc3} alt={props.image} className='img-fluid' />
            </div>
            </div>
            <div className="col-md-4">
            <div class="img_contain">
            <img src={imgsrc4} alt={props.image} className='img-fluid' />
            </div>
            </div>
            <div className="col-md-4">
            <div class="img_contain">
            <img src={imgsrc5} alt={props.image} className='img-fluid' />
            </div>
            </div>
            <div className="col-md-4">
            <div class="img_contain">
            <img src={imgsrc6} alt={props.image} className='img-fluid' />
            </div>
            </div>
    </>
  );
}

export default Commoncorevalue;
