import React from 'react';
import Footer from '../components/Footer';
import Headerin from '../components/Headerin';
import Internalcommon from '../components/Internalcommon';
import Breadcrumsec from '../components/Breadcrumsec';
import serviceimg from '../images/aviation/side.png'
import voicebotimg from '../images/About_Us_Right.png'
import Internalsection from '../components/Internalsection';
import Aboutmision from '../components/Aboutmision'
import Corevalue from '../components/Corevalue';
const Disclaimer = () => {
  return (
    <>
       <Headerin />
   <div className="about_area">
       <Internalcommon  title="Disclaimer" desc="is a voice based user interface that can
understand natural languages and can interact
with users based on voice interface." imgsrc={serviceimg}/>
   </div>
   <Breadcrumsec  maintitle="Home" subtitle="Disclaimer" />
  
   <Internalsection title="iAssist : Disclaimer" span_heading="Become an indutry leader" subtitle="with advance AI solution in Aviation" para1="iAssist Voicebot can understand natural languages and
use voice based conversation with users. Voicebot provides
businesses with a scalable user interaction platform based
on voice and when combined with an advanced Chatbot
shall considerably enhance customer interaction capability." para2="Optimal implementation shall facilitate the organisation to
achieve the intended customer support standards. That is,
to interact with customer’s seamlessly and resolve their
issues upto their satisfaction in an acceptable time frame.
Combination of iAssist Voicebot and Chatbot can enhance
the user experience immensely and drastically reduce the
Operating cost of the organisation." imgsrc={voicebotimg}/>
 <Aboutmision title="Our Mission" subheading="You think, We assist" para="Optimal implementation shall facilitate the organisation to
achieve the intended customer support standards. That is,
to interact with customer’s seamlessly and resolve their
issues upto their satisfaction"/>
<Aboutmision title="Our Vision" subheading="You think, We assist" para="Optimal implementation shall facilitate the organisation to
achieve the intended customer support standards. That is,
to interact with customer’s seamlessly and resolve their
issues upto their satisfaction"/>
<Corevalue/>
<Footer/>
    </>
  );
}

export default Disclaimer;
