import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';

import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import Voicebotcases from '../../components/Voicebotcases';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/voicebot/Voice_Chat_Right_Banner.png'

const Voicebot = () => {
  return (
    <>
      <Headerin />
      <div className="voice_assist_are">
        <Internalcommon title="AI Driven Voicebot" desc="Voice based user interface than understands natural languages and interacts with users." imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" subtitle="Voicebot" />
      <Internalsection title="iAssist : Voicebot" span_heading="Speech to Text (ASR) and Text to Speech (TTS) "
       subtitle="together form the ear and mouth of the virtual voice assistant for Voice Bot"
        para1="Voicebot is an artificial intelligence (AI) and natural language understanding (NLU) based voice channel for any communication that converts speech to text format. iAssist’s AI driven NLP understands the user query, analysis the intent and sentiments to produce the best response in form of user understandable speech through Text to Speech (TS) engine." 
        para2="iAssist voice assistant can be easily integrated into various devices of customer service like – IVR, chatbot, self-service, etc. to extend seamless experience to the customers of every industry. It can notably be used for caller authentication for critical industries and operations like Banking, Insurance etc." imgsrc={voicebotimg} />
      {/* <Voicebotcases /> */}
      <Voicebotcases title="Key Features of iAssist Voicebot" />
      <Consultblock />

      <Footer />

    </>
  );
}

export default Voicebot;
