import React from 'react';

const Commonresource = (props) => {
    return (
        <>
       
            <div class="card-body  p-0 ">
            <img src={props.imgsrc} alt={props.image} className='img-fluid' />
                <div class="py-3"> 
                    <h5 class="my-3 text-uppercase">{props.title}</h5>
                    <p className='tx'>{props.descp}</p>
                   
                </div>
                
            </div>
          
        </>
    );
}

export default Commonresource;
