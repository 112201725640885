import React from 'react';
import Solutionboxcommon from './Solutionboxcommon';
import Solutionboxcommonreverse from './Solutionboxcommonreverse';
import customerservice from '../images/whatwedo/img1.png'
import notification from '../images/whatwedo/img2.png'
import booking from '../images/whatwedo/img3.png'
import booking1 from '../images/whatwedo/img4.png'
import booking2 from '../images/whatwedo/img5.png'
import booking3 from '../images/whatwedo/img6.png'
const Commonsolution = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-8">
      <h1 class="fw-600">iAssist can help organization to increase customer engagement & Cross selling and automate processes.</h1>
      <p className='p-0 pb-5 m-0'>And hence decrease costs, increase customer acquisition and
retention, improve satisfaction, improve experiences, improve
productivity and maximize RoI.</p>
      </div>
      </div>
        <Solutionboxcommon imgsrc={customerservice} title="Provide Customizable NLP Framework" para="iAssist is a flexible turnkey platform. All you have do is select your desired components, share your requirements, highlight the end-objectives and entrust the rest on our AI experts to do the magic!"/>
  <Solutionboxcommonreverse imgsrc={notification} title="Round-the-clock Support" para="Assisto is available 24x7x365, just a call, message, email away! With its flexibility, the iAssist platform can be designed to provide any kind of support your company needs. Be it a simple Support BOT, Helpdesk BOT, or a Feedback BOT. iAssist guarantees that it will always be there to assist your customers- anytime, any day, anywhere." className="d-flex flex-column-reverse"/>
  <Solutionboxcommon imgsrc={booking} title="Chat Analytics" para="Statistics and analytics are essential to evaluate the performance of the BOT. Optimisation of BOT platform is done based on these performance parameters under various circumstances. BOT monitoring parameters preconfigured are not exhaustive, based on user input, new parameters can be introduced to improve the BOT performance."/>
  <Solutionboxcommonreverse imgsrc={booking1} title="Active Learning" para="Lifelong learners, agelong agents. Every conversation and every experience is a learning opportunity for iAssist BOTs. Through interactions, BOTs become more prepared to face similar situations, making the process faster, shorter, and more precise in future." className="d-flex flex-column-reverse"/>
  <Solutionboxcommon imgsrc={booking2} title="Third Party Integrations" para="Adaptable, adjustable, and open-minded. Our BOTs are very flexible and can easily be integrated with any Third Party systems, be it an application, webservice or even a corporate system."/>
  <Solutionboxcommonreverse imgsrc={booking3} title="Brilliant AI Team" para="The brains behind our BOTs. Assisto has an excellent team of brilliant AI specialists working together to redefine the world of Chatbots, Voicebots and to provide enterprises with ingenious solutions. Our team stands by your side, giving support and advice throughout your BOT experience." className="d-flex flex-column-reverse"/>

    </>
  );
}

export default Commonsolution;
