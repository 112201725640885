
// import voicecase1 from '../images/voicebot/Lead_Generation.png'
// import voicecase2 from '../images/voicebot/Customer_Aqq.png'
// import voicecase3 from '../images/voicebot/Customer_Support.png'
// import voicecase4 from '../images/voicebot/Customer_Engagement.png'
// import voicecase5 from '../images/voicebot/Login-signup.png'
// import voicecase6 from '../images/voicebot/Digital_Transformation.png'
// import voicecase7 from '../images/voicebot/Payment_Transaction.png'
// import voicecase8 from '../images/voicebot/Employee_training.png'
import voicecase1 from '../images/conversation/Language-Detection.png'
import voicecase2 from '../images/conversation/Multilingual.png'
import voicecase3 from '../images/conversation/Personilisation-user-calling.png'
import voicecase4 from '../images/conversation/Speech-Text.png'
import voicecase5 from '../images/conversation/Text-Speech.png'
import voicecase6 from '../images/conversation/voice-biometric-authentication.png'
import voicecase7 from '../images/conversation/Voice-CommandActivation.png'
import voicecase8 from '../images/conversation/Digital-Transmition-with-PBX.png'
const Voicecasedata=[

    {
        voicecaseimg:voicecase1,
        voicecasetitle:"Language Detection",
    },
    {
        voicecaseimg:voicecase2,
        voicecasetitle:"Multilingual",
    },
    {
        voicecaseimg:voicecase3,
        voicecasetitle:"Personilisation user calling",
    },
    {
        voicecaseimg:voicecase4,
        voicecasetitle:"Speech to Text",
    },
    {
        voicecaseimg:voicecase5,
        voicecasetitle:"Text to Speech",
    },
    {
        voicecaseimg:voicecase6,
        voicecasetitle:"voice authentication",
    },
    {
        voicecaseimg:voicecase7,
        voicecasetitle:"Voice Command Activation",
    },
    {
        voicecaseimg:voicecase8,
        voicecasetitle:"Digital Transmition with PBX",
    },
   
    // voicebot-cases
    // {
    //     voicecaseimg:voicecase1,
    //     voicecasetitle:"Lead Generation",
    // },
    // {
    //     voicecaseimg:voicecase2,
    //     voicecasetitle:"Customer Acquisition",
    // },
    // {
    //     voicecaseimg:voicecase3,
    //     voicecasetitle:"Customer Support",
    // },
    // {
    //     voicecaseimg:voicecase4,
    //     voicecasetitle:"Customer Engagement",
    // },
    // {
    //     voicecaseimg:voicecase5,
    //     voicecasetitle:"Login/Sign-up",
    // },
    // {
    //     voicecaseimg:voicecase6,
    //     voicecasetitle:"Digital Transformation",
    // },
    // {
    //     voicecaseimg:voicecase7,
    //     voicecasetitle:"Payment Transaction on Chatbot",
    // },
    // {
    //     voicecaseimg:voicecase8,
    //     voicecasetitle:"Employee training & On-boarding",
    // },

 
    

]

export default Voicecasedata;