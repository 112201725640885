import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/Health_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'

const Healthcare = () => {
  return (
    <>
      <Headerin />
      <div className="Healthcare_area">
        <Internalcommon title="Health Care" desc="Reforming Healthcare with Conversational AI" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Health Care" />
      <Internalsection title="iAssist : Health Care" span_heading="An unprecedented, industry-leading AI/ML based approach to automating conversations for healthcare"
       para1="AI can connect multiple CX channels, service departments and backend systems for a fully integrated experience, eliminating friction points that erode member trust while driving personalized, empathetic care."
 para2="Overpayment and recovery losses are major healthcare cost factors. Conversational AI can drive costs down by flagging potential billing issues during interactions and automating next-best actions for efficient resolution." 
 para3="Conversational AI technologies have the potential to free healthcare delivery from the constraints of geography and brick and mortar sites."
 imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Healthcare.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="25%" value2="60%" value3="40%"   text1="cost saving by flagging potential billing issues during interactions & automating" 
      text2="reduction in call center cost by automating customer support requests" text3="increase in sales through Omni Channel presence" />
      </div>
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Patient’s Virtual Assistant" 
          list1="- Conversational AI"   list2="- Voice Bot"   list3="- Instant Analytics" 
          casepara1="&bull; Connecting the PMS & ERP management API to chatbot helps in simplifying the process of customer engagement through:
          " 
          casepara2="&bull; Scheduling appointment with right medics
          Guide members and patients on preventive care services Illness Symptoms Checker
          Personalised patient virtual assistant
          "
          
          title2="24x7 Support" 
          list11="- Conversational AI"   list12="- Voice Bot"
          casepara11="&bull; Engage patients and their near and dear ones instantly through chat/voice bots for:"
          casepara12="&bull; 24x7 FAQ resolution
          24X7 access to medical knowledge bank Insurance details and process
          Billing related queries
          "
         
          img2={img2}

          img3={img3} 
          title12="Process Control" 
          list21="- Conversational AI" 
          casepara21="&bull; Gather data points required by third party tools from users for" 
        
          />
          <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Inventory Management</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                  
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <p>Connecting the Inventory management API to chatbot helps in:
</p>
<ul className='list-unstyled'>
  <li>&bull; Procurement optimization</li>
  <li>&bull; Operations Automation</li>
  <li>&bull; Planning teams</li>
</ul>
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default Healthcare;
