import React from 'react';

const Commonclient = (props) => {
    return (
        <>
            <div className="col py-md-4 py-1">
                <img src={props.clientlogo} alt="client1" className='img-fluid ' />
            </div>
        </>
    );
}

export default Commonclient;
