import React from 'react';

const Commonvoicecase = (props) => {
  return (
    <>
       <div className="col-md-3 col-xxl-3 col-sm-3 col-6 text-left">
        <div className="box text-center">
       <img src={props.iconimg} alt="img"  className="img-fluid col-md-4 col-8 mx-auto"/>
       <h4 className='py-4 text-uppercase text-center text-white '>{props.title}</h4>
       </div>
      </div>
      
    </>
  );
}

export default Commonvoicecase;
