import React from 'react';

const Internalsection = (props) => {
    return (
        <>
        <div className="common_sec py-5">
        <div className="container">
                    <div className="row ">
                       
                        <div className="col-md-6 col-12 text-dark">
                            <h1 className='head_main'>{props.title}</h1>
                            <h4 className='head_sub col-md-8 col-12'><span className='span_style'>{props.span_heading}</span> {props.subtitle}</h4>
                            <p className='py-2 col-md-10 col-12'>{props.para1}</p>
                            <p className='col-md-10 col-12'>{props.para2}</p>
                            <p className='col-md-10 col-12'>{props.para3}</p>
                            
                        </div>
                        <div className="col-md-5 col-12">
                            <img src={props.imgsrc} alt="img" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Internalsection;
