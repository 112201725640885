import React from 'react';
import Commonclient from './Commonclient';
import Clientdata from './Clientdata'
const Ourclients = () => {
    return (
        <>
            <div className="bg-style client_area  py-md-5 py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12 text-white">
                            <h1 className='main_heading'>Our Elite Clients</h1>
                            
                        </div>
                    </div>
                    <div className="row py-5 row-cols-5 ">
                    {
                                    Clientdata.map((val, ind) => {
                                        return (
                                            <Commonclient key={ind} clientlogo={val.iconimg} />
                                        )
                                    }
                                    )
                                }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ourclients;
