import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/BFSI_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const BFSI = () => {
  return (
    <>
      <Headerin />
      <div className="bfsi_area">
        <Internalcommon title="BFSI" desc="Empowering the Banking Operations &nbsp; & Financial Services with Conversational AI" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="BFSI" />
      <Internalsection title="iAssist : BFSI" span_heading="Personalized financial guidance is a method banks can use to enhance the customer experience" 
      para1="Conversational AI in banking can help you understand and resolve customer queries by automating repetitive processes that typically take a bank employee much longer.  Better efficiency and faster solutions improve your credibility too – which is vital in the banking space." para2="iAssist can help financial institutions to increase customer engagement & Cross selling, automate processes & hence decrease costs, increase customer acquisition and retention, improve satisfaction, improve experiences, improve productivity and maximize RoI."  imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Banking.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="90%" value2="57%" value3="$7.3B" text1="of interactions in banks will be automated by 2022 using chatbots." 
      text2="of consumers are interested in chatbots for instantaneous responses." text3="of the operational cost will be saved using chatbots in the banking sector by 2023." />

      </div>
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Block credit/debit card " 
          list1="- Conversational AI" list2="- ASR" list3="- Voice Authentication" list4="- Face recognition"
          casepara1="&bull; Voice based &/or facial recognition for account login" 
          casepara2="&bull; Accept request to block the card through conversational AI "
      
          title2="Ease of documentation" 
          list11="- Conversational AI" list12="- ASR" list13="- Voice Authentication" 
          casepara11="&bull; Gather the required documentation online through bot"
          casepara12="&bull; For existing customers, voice recognition can be used as an additional layer if verification is needed "
         
          img2={img2}

          img3={img3} 
          title12="Reset Passwords" 
          list21="- Conversational AI" list22="- ASR" list23="- Voice Authentication" list24="- Face recognition" 
          casepara21="&bull; Voice based & facial recognition for bio authentication
          " 
          casepara22="&bull; Bot asks to set new password"
          casepara23="&bull; Reset Password confirmation sent over SMS"
          />
           <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Customer Support</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- ASR</li>
                   <li>- Voice Authentication</li>
                  <li>- Face recognition</li>
                  <li>- Conversational AI
</li>
                  
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <ul className='list-unstyled'>
                <li>&bull; Voice based & facial recognition for bio authentication</li>
                <li>&bull; User requests/ queries will be addressed through conversational AI
</li>
                <li>&bull; Reset Password confirmation sent over SMS</li>
               </ul>
              
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
   
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default BFSI;
