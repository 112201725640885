import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../images/assisto.png'
function Header() {
  return (
    <>
      <div className="header  border-3 border-top border-primary">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-default">

            <NavLink className="navbar-brand" to="/" >
              <img src={logo} alt="assisto"  width="130px"/>
            </NavLink>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-default" aria-controls="navbar-default" aria-expanded="true" aria-label="Toggle navigation">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi" fill="currentColor" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
</svg>

    </button>
            <div className="navbar-collapse collapse" id="navbar-default" >
            
              <ul className="navbar-nav ms-auto me-lg-3 ">
                <li className="nav-item dropdown disabled">
                  <NavLink className="nav-link d-lg-none" to="#">
                    Menu
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link text-uppercase text-white" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle text-uppercase text-white"
                   to="#"
                    id="menu-6"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                Product
                  </NavLink>
                  <ul
                    className="dropdown-menu dropdown-menu-arrow  dropdown-menu-xl-left "
                    aria-labelledby="menu-6"
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/ConversationalAi">
                        Conversational AI
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Voicebot">
                        Voice Bot
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/InstantBiAnalatics">
                      Instant BI & Analytics
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle text-uppercase text-white"
                   to="#"
                    id="menu-6"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                Solutions
                  </NavLink>
                  <ul
                    className="dropdown-menu dropdown-menu-arrow  dropdown-menu-xl-left "
                    aria-labelledby="menu-6"
                  >
                    <li>
                    <NavLink className="dropdown-item" to="/Telecommunication">
                        Telecom
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/BFSI">
                        BFSI
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Logistics">
                      Logistics
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/OilGas">
                      Oil & Gas
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/RetailECommerce">
                      Retail & E-Commerce
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/GovernmentPSU">
                      Government & PSU
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Aviation">
                      Aviation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/FoodBeverage">
                      Food & Beverage
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/ITEngineering">
                      IT & Engineering
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Manufacturing">
                      Manufacturing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/DataCentres">
                      Data Centres
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Healthcare">
                      Healthcare
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle text-uppercase text-white"
                   to="#"
                    id="menu-6"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                Services
                  </NavLink>
                  <ul
                    className="dropdown-menu dropdown-menu-arrow  dropdown-menu-xl-left "
                    aria-labelledby="menu-6"
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/Whatwedo">
                       What We Do
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle text-uppercase text-white"
                   to="#"
                    id="menu-6"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                Resources
                  </NavLink>
                  <ul
                    className="dropdown-menu dropdown-menu-arrow  dropdown-menu-xl-left "
                    aria-labelledby="menu-6"
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/Securitypolicy">
                      Security Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Releasenote">
                      Realease Note
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Trainningmanual">
                      Training Mannual
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Reportgeneration">
                     Report Generation
                      </NavLink>
                    </li>
                  </ul>
                </li>
               
                <li className="nav-item">
                  <NavLink className="nav-link text-uppercase text-white" to="/About">
                    Company
                  </NavLink>
                </li>
                
              </ul>
              <div className="header-btn ">
                <NavLink to="/Contact" className=" btn_style  text-uppercase">
                  Contact Us
                </NavLink>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>

  )
}

export default Header
