import React from 'react';
import article2 from "../../images/Resource_02.jpg";
import Headerin from '../../components/Headerin';
import Footer from '../../components/Footer';
import { NavLink } from 'react-router-dom';
import Commonresource from '../../components/Commonresource';
const Releasenote = () => {
  return (
    <>
      <Headerin />
      <div className="bg-white  py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-12 text-dark">
              <h1 className='main_heading' >Resouces</h1>
            </div>
          </div>
          <div className="row py-5">
      
            <div className="col-md-4 mb-5">
            <NavLink to="/resource/partner/march_2022.pdf" target="_blank" className=" d-block  border border-light bg-light p-3 shadow_box text-uppercase  text-uppercase  text-primary-500 fw-600 text-decoration-none"  >
              <Commonresource imgsrc={article2} title="Release Note March 2022"
              />
              Read Full Articles</NavLink>
            </div>
            <div className="col-md-4 mb-5">
            <NavLink to="/resource/partner/june_2022.pdf" target="_blank" className=" d-block  border border-light bg-light p-3 shadow_box text-uppercase  text-uppercase  text-primary-500 fw-600 text-decoration-none"  >
              <Commonresource imgsrc={article2} title="Release Note June 2022"
              />
              Read Full Articles</NavLink>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default Releasenote

