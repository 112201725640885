import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/Gove_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const GovernmentPSU = () => {
  return (
    <>
      <Headerin />
      <div className="GovernmentPSU_area">
        <Internalcommon title="Government & PSU" desc="Reforming Governance with Conversational AI" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Government & PSU" />
      <Internalsection title="iAssist : Government & PSU" span_heading="An unprecedented, industry-leading AI/ML based approach to automating conversations for Government & PSU" 
      para1="iAssist is a Made-in-India holistic NLP framework for AI enabled Virtual Assistants that delivers a digital-first, human-like conversational experience through voice and text interactions with patients, members, caregivers, providers, agents, employees and consumers across the enterprise." 
      para2="Chatbots, virtual assistants, and messaging-based applications are all examples of conversational AI systems that are opening up new channels for government service delivery. These platforms are made up of a number of connected artificial intelligence (AI) technologies that allow for a more engaging with the Citizens." 
       imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Govt_PSU.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="$0.50" value2="40%" value3="25%" 
      text1="reduction in governance cost per G2C interaction" 
      text2="user prefer to interact with bot and get answers quickly"
      text3="increase efficiency in process of addressal system"  />
      </div>
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Civic Engagement" 
          list1="- Conversational AI"  
          casepara1="Collect user information through conversational AI
          " 
          casepara2="Connect to Government Services API to:
          &bull; Address user request / queries
          &bull; Provide services like tax filing forms, Public health details, Housing services related info, etc "
          
          title2="Call centre Support " 
          list11="- Conversational AI" list12="- ASR" list13="- Voice Authentication" list14="- Face recognition" 
          casepara11=" &bull; Voice based & facial recognition for bio authentication of the citizens/ users"
          casepara12=" &bull; Through conversational  ai, Government can push notifications to the citizens relating to Public health, Citizen welfare, etc. while citizens can call to raise their grievances, track tickets raised, etc.
          "
         
          img2={img2}

          img3={img3} 
          title12="Inventory Management" 
          list21="- Conversational AI"  list22="- Instant Analytics" 
          casepara21="&bull; Connecting the Inventory management API to chatbot helps in simplifying the process for procurement, operations and planning teams
          " 
          
          />
           <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Data Analysis</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                   <li>- Instant Analytics</li>
                   
                 
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <p>&bull; Using NL2SQL we can fetch relevant dataset and feed it into conversational AI for further filtering analysis and charting for prediction for use cases like:</p>
               <ul className='list-unstyled'>
                <li>&bull; Citizen program performance
</li>
                <li>&bull; Project Implementation tracking</li>
                <li>&bull; Project inventory tracking</li>
                <li>&bull; Project progress tracking
</li>
<li>&bull; Categorize citizen grievances for project control</li>
               </ul>
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default GovernmentPSU;
