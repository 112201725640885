import React from 'react';
import './App.css';
import './Custom.js';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Home from './pages/Home';
import Resource from './pages/Resource';
import { Redirect, Route, Switch } from 'react-router-dom';
import Voicebot from './pages/product/Voicebot';
import Aviation from './pages/solution/Aviation';
import Contact from './pages/Contact';
import InstantBiAnalatics from './pages/product/InstantBiAnalatics';
import ConversationalAi from './pages/product/ConversationalAi';
import About from './pages/About';
import Financing from './pages/Financing';
import Privacypolicy from './pages/Privacypolicy';
import Termsofservice from './pages/Termsofservice';
import Disclaimer from './pages/Disclaimer';
import Testpop from './pages/Testpop';
import Telecommunication from './pages/solution/Telecommunication';
import Whatwedo from './pages/services/Whatwedo';
import Securitypolicy from './pages/resources/Securitypolicy';
import Releasenote from './pages/resources/Releasenote';
import Trainningmanual from './pages/resources/Trainningmanual';
import Reportgeneration from './pages/resources/Reportgeneration';
import BFSI from './pages/solution/BFSI';
import DataCentres from './pages/solution/DataCentres';
import FoodBeverage from './pages/solution/FoodBeverage';
import GovernmentPSU from './pages/solution/GovernmentPSU';
import Healthcare from './pages/solution/Healthcare';
import ITEngineering from './pages/solution/ITEngineering';
import Logistics from './pages/solution/Logistics';
import Manufacturing from './pages/solution/Manufacturing';
import OilGas from './pages/solution/OilGas';
import RetaileCommerce from './pages/solution/RetaileCommerce';



function App() {
  return (
  <>
    <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/ConversationalAi" component={ConversationalAi} />
    <Route exact path="/InstantBiAnalatics" component={InstantBiAnalatics} />
    <Route exact path="/Voicebot" component={Voicebot} />
    <Route exact path="/Aviation" component={Aviation} />
    <Route exact path="/Resource" component={Resource} />
    <Route exact path="/Telecommunication" component={Telecommunication} />
    <Route exact path="/Whatwedo" component={Whatwedo} />
    <Route exact path="/About" component={About} />
    <Route exact path="/Contact" component={Contact} />
    <Route exact path="/Securitypolicy" component={Securitypolicy} />
    <Route exact path="/Releasenote" component={Releasenote} />
    <Route exact path="/Trainningmanual" component={Trainningmanual} />
    <Route exact path="/Reportgeneration" component={Reportgeneration} />
    <Route exact path="/Testpop" component={Testpop} />
    <Route exact path="/Financing" component={Financing} />
    <Route exact path="/Disclaimer" component={Disclaimer} />
    <Route exact path="/Privacypolicy" component={Privacypolicy} />
    <Route exact path="/Termsofservice" component={Termsofservice} />
    <Route exact path="/BFSI" component={BFSI} />
    <Route exact path="/DataCentres" component={DataCentres} />
    <Route exact path="/FoodBeverage" component={FoodBeverage} />
    <Route exact path="/GovernmentPSU" component={GovernmentPSU} />
    <Route exact path="/Healthcare" component={Healthcare} />
    <Route exact path="/ITEngineering" component={ITEngineering} />
    <Route exact path="/Logistics" component={Logistics} />
    <Route exact path="/Manufacturing" component={Manufacturing} />
    <Route exact path="/OilGas" component={OilGas} />
    <Route exact path="/RetaileCommerce" component={RetaileCommerce} />


    <Redirect to="/" />
      </Switch>
  </>
  );
}

export default App;
