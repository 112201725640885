import React from 'react';
import Commonfeature from './Commonfeature';
import Sdata from './Sdata'
const Feature = () => {
    return (
        <>
            <div className="feature_area  py-5">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-md-5 col-12">
                        <h1 className='main_heading'>iAssist Features</h1>
                    <h4 className='sub_heading'>One Platform, Many Capabilities <br/>
Unlock new possibilities, Boost productivity and Amplify your impact.</h4>
                        </div>
                    </div>
                    
                    <div className="row pt-5 gx-md-5 gx-1">
                        {
                            Sdata.map((val, ind) => {
                                return (
                                    <Commonfeature key={ind} iconimg={val.iconimg} title={val.title} />
                                )
                            }
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Feature;
