import React from 'react';
import Breadcrumsec from '../components/Breadcrumsec';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Headerin from '../components/Headerin';
import Internalcommon from '../components/Internalcommon';
import serviceimg from '../images/aviation/Contact_Us.png'


const Contact = () => {
  return (
    <>
        <Headerin />
   <div className="contact_area">
       <Internalcommon  title="Get in Touch" desc="Assured 24x7 support to our customers reach us to schedule a demo or become our trusted partner" imgsrc={serviceimg}/>
   </div>
   <Breadcrumsec  maintitle="Home" subtitle="Conatct Us" />
 <div className="form_area py-5">
     <div className="container">
         <div className="row py-5">
             <div className="col-md-6">
                 <h1 className='main_heading'>Reach Us</h1>
                 <div className="box_contact py-3">
                 <h4 class="sub_heading">Assisto Technologies Pvt. Ltd.</h4>
                 <p className='col-md-6 col-12'>B-1029, Ithum Tower, Galaxy Business Park, <br/>
                 Sector 62, Noida, Uttar Pradesh 201309</p>
                 </div>
                 <div className="box_contact pb-3">
                 <h4 class="sub_heading">Email</h4>
                 <p className='col-md-6 col-12'>sales@assisto.tech</p>
                 </div>
                 <div className="box_contact pb-3">
                 <h4 class="sub_heading">Call Now</h4>
                 <p className='col-md-6 col-12'>+91-88106 80943</p>
                 </div>
             </div>
             <div className="col-md-6">
                 <h2>Let us know about you</h2>
                <ContactForm/>
             </div>
         </div>
     </div>
 </div>
<div className="map_area">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.0766906505837!2d77.36810172581445!3d28.62746352618928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce509f4302193%3A0x5a710bdbdee5c2ba!2si-Thum%20Tower!5e0!3m2!1sen!2sin!4v1659790330810!5m2!1sen!2sin"
width="100%"
height="450"
frameBorder="0"
style={{ border: 0 }}
allowFullScreen=""
aria-hidden="false"
tabIndex="0"
title='map'

/>
</div>
   <Footer />
    </>
  );
}

export default Contact;
