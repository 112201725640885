import React from 'react';

const Internalcommon = (props) => {
  return (
    <>
     <div className="container">
                    <div className="row justify-content-center align-items-baseline">
                       
                        <div className="col-md-6 col-12 text-white">
                            <h1 className='head_main'>{props.title}</h1>
                            <h6 className='head_sub col-md-10 col-12'>{props.desc}</h6>
                        </div>
                        <div className="col-md-6">
                            <img src={props.imgsrc} alt="img" className='img-fluid' />
                        </div>

                    </div>
                </div>
      
    </>
  );
}

export default Internalcommon;
