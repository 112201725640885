import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
// import Casestudy from '../../components/Casestudy';
// import Caibenefits from '../../components/Caibenefits';
import Consultblock from '../../components/Consultblock';
// import Feature from '../../components/Feature';
import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import Conversationcase from '../../components/Conversationcase';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/voicebot/Voice_Chat_Right_Banner.png'

const ConversationalAi = () => {
  return (
    <>
      <Headerin />
      <div className="conversational_area">
        <Internalcommon title="Conversational AI" desc="A cost-effective & more efficient alternate to agent- assisted conversations!
" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" subtitle="Conversational AI" />
      <Internalsection title="iAssist Conversational Bot" span_heading="Engage your customers through any channel efficiently by changing your BOT not customer behaviour. 
   " para1="Conversations are complex human behaviour used to discuss around a goal, ex. Booking a ticket, purchase a product etc. This behaviour becomes more complex when different people follow different ways and means to hold a conversation. Hence it becomes difficult for the BOT / Conversational AI / NLP to understand the user input. 
   " para2="iASSIST Chat Bot has been benchmarked against human agent for executing user-bot conversations human-like to help organisation scale their operations and support their customers better." imgsrc={voicebotimg} />
       <div className="container pb-5">
    <div className="row">
      <div className="col-md-12">
      <h4>Key characteristics of effective Conversational AI</h4>
<ul className='list_count_new_style'>
  <li>It is able to understand inputs that are grammatically incorrect from Non native language speakers
</li>
  <li>It performs when User may input one sentences through multiple inputs i.e. multiple input one intent</li>
  <li>It understands input when User may also club multiple intents in one sentence</li>
  <li>It can maintain the state of conversation and record of previous conversations to avoid asking user for information previously provided</li>
  <li>It Detects sentiment and handovers to human agent when sentiment is deeply negative
</li>
  <li>It is capable of learning from every user interaction so the bot becomes smarter day by day
</li>
</ul>
      </div>
      </div> 
    </div>
      {/* <Caibenefits /> */}
      <Conversationcase title="Key Features of iAssist Conversational AI" />

      {/* <Feature /> */}
      <Consultblock />
      {/* <Casestudy /> */}
      <Footer />

    </>
  );
}

export default ConversationalAi;
