import React from 'react';
import { NavLink } from 'react-router-dom';
import serviceimg from '../images/Services_Image.png'
const Ourservices = () => {
    return (
        <>
            <div className="ourservice_area  py-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <img src={serviceimg} alt="img" className='img-fluid' />
                        </div>
                        <div className="col-md-6 col-12 text-dark">
                            <h1 className='main_heading'>Our Services</h1>
                            <h4 className='sub_heading'>We provide iAssist API, which is a complete suite of Instant Analytics & Conversational AI capabilities, to our Tech/Channel partners to meet or exceed the customer expectations by ensuring:</h4>
                            <ul className="list py-3">
                                <li>Customizable NLP Platforms</li>
                                <li>Third Party Tool Integrations</li>
                                <li>Round-the-clock Support</li>
                            </ul>
                            <NavLink to="/Whatwedo" className=" text-uppercase btn_style"  >Explore More</NavLink>
                        </div>

                    </div>
                </div>
            </div>
            
        </>
    );
}

export default Ourservices;
