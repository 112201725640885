import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/IT_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const ITEngineering = () => {
  return (
    <>
      <Headerin />
      <div className="ITEngineering_area">
        <Internalcommon title="IT & Engineering" desc="Reforming IT with Conversational AI" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="IT & Engineering" />
      <Internalsection title="iAssist : IT & Engineering" span_heading="Reforming IT Landscape through AI/ML automating processes and helping organizations achieve 60% faster decision making and 40% cost reduction. SPEAK THE LANGUAGE OF YOUR CUSTOMERS Instead make them to work with complex applications." 
      para1="iASSIST platform is scalable, multilingual, and device-independent is needed to deliver conversational AI apps that develop as the business expands. One that is simple to use while supporting enterprise-level call and text volumes, and can effortlessly interact with back end systems and third-party applications." 
       para2="Use iASSIST Conversational AI to create more tailored and interesting consumer experiences in using Enterprise Applications. Easily design a variety of conversational interfaces. Build once, distribute across platforms and channels, and use different languages. Customers can speak or type as they normally would when interacting with technology using natural language, which frees them from having to follow prompts. Allowing customer interaction across channels will improve brand experience, reduce time to resolution, and foster advocacy and loyalty."
       imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_IT.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="60%" value2="40%" value3="50%"   text1="Faster decision making" 
      text2="cost savings" text3="Bandwidth for Innovation" />
      </div>
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Reltime Tech Support
          " 
          list1="- Conversational AI" list2="- ASR" list3="- Voice Authentication"  list4="- Face recognition" 
          casepara1="&bull; Voice based & facial recognition for bio authentication" 
          casepara2="&bull; User requests/ queries will be addressed through conversational AI in realtime
          " 
        
          
          title2="Dashboard Analysis" 
          list11="- Conversational AI" list12="- Instant Analytics
          " 
          casepara11="&bull; NL2SQL can fetch relevant dataset and feed it into conversational AI for further filtering, analysis and visualization of:"
          casepara12="&bull; Security KPI Analytics
          Correlation of multi device KPI analytics for threat identification Product KPI analytics
          Business data analytics
          "
        
          img2={img2}

          img3={img3} 
          title12="Report Analysis/ Instant BI" 
          list21="- Conversational AI" list22="- Instant Analytics" 
          casepara21="&bull; NL2SQL can fetch relevant dataset instantly from the database and feed it to conversational AI to generate customized reports for: 
          " 
          casepara22="&bull; Business data analytics
          Incident analytics
          Marketing analytics
          "
          />
      <Consultblock />

      <Footer />

    </>
  );
}

export default ITEngineering;
