import React from 'react';
import { NavLink } from 'react-router-dom';

const Breadcrumsec = (props) => {
  return (
    <>
    <div className="container">
    <nav>
  <ol className="breadcrumb m-0 py-2">
    <li className="breadcrumb-item"><NavLink to="#" className="text-dark">{props.maintitle}</NavLink></li>
    {/* <li className="breadcrumb-item ">{props.categorytitle}</li> */}
    <li className="breadcrumb-item active" aria-current="page">{props.subtitle}</li>
  </ol>
</nav>
    </div>
   
    </>
  );
}

export default Breadcrumsec;
