import React from 'react';
import banner1 from "../images/Masscot.png";
import { NavLink } from 'react-router-dom';
const Commonslider = (props) => {
    return (
        <>
            <div className="row d-flex align-items-center ">
                <div className="col-md-6 col-xxl-6 col-sm-6 col-12 text-white ">
                    <h1 className='head_main'>{props.title}</h1>
                    <h4 className='head_sub col-md-6 col-12'>{props.subtitle}</h4>
                    <NavLink to="/ConversationalAi" className=" text-uppercase my-3 py-2 px-4 btn_style"  >{props.btn_link}</NavLink>
                </div>
                <div className="col-md-6 col-xxl-6 col-sm-6 col-12 pt-5">
                    <img src={banner1} alt="banner1" className="img-fluid pt-5 col-md-10 mx-auto " />
                </div>
            </div>
        </>
    );
}

export default Commonslider;
