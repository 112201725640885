import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/Manufacturing_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const Manufacturing = () => {
  return (
    <>
      <Headerin />
      <div className="Manufacturing_area">
        <Internalcommon title="Manufacturing" desc="Conversational AI for Manufacturing" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Manufacturing" />
      <Internalsection title="iAssist : Manufacturing" span_heading="Industry 4.0 has revolutionised the
manufacturing industry." para1="with advanced tooling and machinery offering significant productivity gains." 
para2="Conversational AI extends the benefits of technology advancement for manufacturers beyond the factory floor and into core business processes. Manufacturers can deliver an average 40% time saving to their operational teams such as HR, but also achieve competitive advantage through improving productivity and order delivery times, improving customer satisfaction and reputation." 
 imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Manufacturing.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="60%" value2="40%" value3="30%"  text1="Faster decision
making" 
      text2="Cost saving"  text3="Better Customer" />
      </div>
  
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Inventory Management" 
          list1="- Conversational AI" 
          casepara1="&bull; Connecting the Inventory management API to chatbot helps in simplifying the process for procurement, operations and planning teams "
      
          
          title2="Data Analysis" 
          list11="- Conversational AI" list12="- Instant Analytics" 
          casepara11="&bull; Using NL2SQL we can fetch relevant dataset and feed it into conversational AI for further filtering analysis and charting for prediction to simplify:"
          casepara12="&bull; Logistics tracking
          Sales Cycle projection 
          Production and Quality control analytics
          Customer grievances for quality control
          "
        
          img2={img2}

          img3={img3} 
          title12="Governance " 
          list21="- Conversational AI"   
          casepara21="&bull; Gather data points required by third party tools from users for Governance
          Inter departmental Process coordination
          Process monitoring
          Process control" 
       
          />
           <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Order placement &</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>

               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
               <ul className='list-unstyled'>
                <li>&bull; RPA to check availability of stock </li>
                <li>&bull; Using conversational AI, request material from respective vendors for specific qty</li>
                <li>&bull; Present various offers as carousal</li>
                <li>&bull; Order finalization, modification, shipment tracking and smart notifications through the bot
</li>
               </ul>
             
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default Manufacturing;
