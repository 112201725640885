
import voicecase1 from '../images/voicebotimg/human_agent_handover.png'
import voicecase2 from '../images/voicebotimg/multi_intent_detection.png'
import voicecase3 from '../images/voicebotimg/omni_channel.png'
import voicecase4 from '../images/voicebotimg/sentiment_detection.png'
import voicecase5 from '../images/voicebotimg/spell_correction.png'



const Conversationcasedata=[

  
    {
        voicecaseimg:voicecase1,
        voicecasetitle:"Human agent handover",
    },
    {
        voicecaseimg:voicecase2,
        voicecasetitle:"Multi intent detection",
    },
    {
        voicecaseimg:voicecase3,
        voicecasetitle:"Omni channel",
    },
    {
        voicecaseimg:voicecase4,
        voicecasetitle:"Sentiment detection",
    },
    {
        voicecaseimg:voicecase5,
        voicecasetitle:"Spell correction",
    },
   
  

 
    

]

export default Conversationcasedata;