import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import partner1 from "../images/Partner_1.jpg";
import partner2 from "../images/Partner_2.jpg";
import partner3 from "../images/Partner_3.jpg";
import partner4 from "../images/Partner_4.jpg";
import partner5 from "../images/Partner_5.jpg";
import partner6 from "../images/partner_6.jpg";
import partner7 from "../images/partner_7.jpg";
import partner8 from "../images/partner_8.jpg";
// Import Swiper styles
import "swiper/css";

const Ourpartner = () => {
  return (
    <>
       <div className="bg-white  py-5">
                <div className="container">
                <div className="row">
                        <div className="col-md-5 col-12 text-dark">
                            <h1 className='main_heading'>Our Partners</h1>
                            <h4 className='sub_heading'>It all starts with choosing the right one!!</h4>
                        </div>
                    </div>
                    <div className="row py-5">
                        <div className="col-md-12">
                        <Swiper
        slidesPerView={2}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 6,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 50,
          },
        }}
       
        className="mySwiper"
      >
        <SwiperSlide>
            <div className="img_box text-center">
                <img src={partner1} alt="client1" className='img-fluid ' />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="img_box text-center">
                <img src={partner2} alt="client1" className='img-fluid ' />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="img_box text-center">
                <img src={partner3} alt="client1" className='img-fluid ' />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="img_box text-center">
                <img src={partner4} alt="client1" className='img-fluid ' />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="img_box text-center">
                <img src={partner5} alt="client1" className='img-fluid ' />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="img_box text-center">
                <img src={partner6} alt="client1" className='img-fluid ' />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="img_box text-center">
                <img src={partner7} alt="client7" className='img-fluid ' />
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="img_box text-center">
                <img src={partner8} alt="client7" className='img-fluid ' />
            </div>
        </SwiperSlide>
       
      </Swiper>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}

export default Ourpartner;
