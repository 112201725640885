import icon1 from '../images/Telecom_Icon.png'
import icon2 from '../images/BFSI_Icon.png'
import icon3 from '../images/Logistics_Icon.png'
import icon4 from '../images/Oil_Gas_Icon.png'
import icon5 from '../images/Retail_Icon.png'
import icon6 from '../images/Government_Icon.png'
import icon7 from '../images/Aviation_Icon.png'
import icon8 from '../images/Food_Beverages_Icon.png'
import icon9 from '../images/Engineering_Icon.png'
import icon10 from '../images/Manufacturing_Icon.png'
import icon11 from '../images/Data_Center_Icon.png'
import icon12 from '../images/Healthcare_Icon.png'
import side1 from '../images/popupimg/Telecom.jpg'
import side2 from '../images/popupimg/BFSI.jpg'
import side3 from '../images/popupimg/Logistic.jpg'
import side4 from '../images/popupimg/Oil_Gas.jpg'
import side5 from '../images/popupimg/Retai_Ecommerce.jpg'
import side6 from '../images/popupimg/Government.jpg'
import side7 from '../images/popupimg/Aviation.jpg'
import side8 from '../images/popupimg/Food&Beverages.jpg'
import side9 from '../images/popupimg/It_Engeneer.jpg'
import side10 from '../images/popupimg/Manufacturing.jpg'
import side11 from '../images/popupimg/Data_Center.jpg'
import side12 from '../images/popupimg/Healthcare.jpg'
import download1 from '../images/Casestudies/Assisto_Telecom.pdf'
import download3 from '../images/Casestudies/Assisto_Logistic.pdf'
import download2 from '../images/Casestudies/Assisto_Banking.pdf'
import download4 from '../images/Casestudies/Assisto_Oil_Gas.pdf'
import download5 from '../images/Casestudies/Assisto_Retail_Ecommerce.pdf'
import download6 from '../images/Casestudies/Assisto_Govt_PSU.pdf'
import download7 from '../images/Casestudies/Assisto_Aviation.pdf'
import download8 from '../images/Casestudies/Assisto_Logistic.pdf'
import download9 from '../images/Casestudies/Assisto_IT.pdf'
import download10 from '../images/Casestudies/Assisto_Manufacturing.pdf'
import download11 from '../images/Casestudies/Assisto_DataCentre.pdf'
import download12 from '../images/Casestudies/Assisto_Healthcare.pdf'
const data={
    cardData:[
        {
            id:1,
            imgsrc:icon1,
            title:"Telecom",
            imgsrc1:side1,
            internal: ['Multi Tool Integrated Dashboard', 'Unified View for Database Analysis', 'Send Reminders, Notifications & deals using Whatsapp, messenger, etc', 'Use Omnichannel BOT for Upsell & Cross Sell ', 'Fraud detection by identifying & analysing violations and suspicious activities', 'Easy Cyber Threat Analysis from Multiple Security Device'],
            external: ['24/7 Customer support in Regional Language', 'Account Management: subscriptions, check balance, reset passwords, etc', 'Know about tariffs, promos, roaming, etc', 'Manage Payment Transactions', 'Troubleshooting: Internet config, Internet speed, etc', 'Order & track online order, request or complaints', 'Ease of documentation', 'Locate nearest service centre'],
            percent:['45% of employees’ time saved', '30% reduction in customer service costs ', '56% boost in revenue through omnichannel'],
            download: download1
        },
        {
            id:2,
            imgsrc:icon2,
            title:"BFSI",
            imgsrc1:side2,
            internal: ['Multi Tool Integrated Dashboard', 'Unified View for Database Analysis', 'Send Reminders, Notifications & deals using Whatsapp, messenger, etc', 'Use Omnichannel BOT for Upsell & Cross Sell ', 'Fraud detection by identifying & analysing violations and suspicious activities', 'Easy Cyber Threat Analysis from Multiple Security Device'],
            external: ['24/7 Customer support in Regional Language', 'Account Management: subscriptions, check balance, reset passwords, etc', 'Know about tariffs, promos, roaming, etc', 'Know about credit cards, loans, etc', 'Manage Payment Transactions', 'Block credit/debit card', 'Order & track online request or complaints', 'Ease of documentation','Locate nearest branch or ATM' ],
            percent:['$7.3B of the operational cost saved by 2023 ', '57% of consumers are interested in chatbots', '60% Better Customer Service with Ai chat bots'],
            download: download2
        },
        {
            id:3,
            imgsrc:icon3,
            title:"Logistics",
            imgsrc1:side3,
            internal: ['Back office Automation', 'supply chain management', 'Route optimization', 'Demand Forecasting', 'Operational cost reduction with mitigated risks', 'Integrate & simplifiy internal processes', 'Easy Cyber Threat Analysis from Multiple Security Device', 'HR Bots'],
            external: ['24x7 customer service Chat/voice bots', 'Delivery lead-time and cost calculator', 'Order Booking & Assistance', 'Order Tracking', 'Feedback and complaint form filling' ],
            percent:['60% save on customer service costs', '30% increase in new clients through omni channel presence', '57% claimed chatbots deliver high ROI'],
            download: download3
        },
        {
            id:4,
            imgsrc:icon4,
            title:"Oil & Gas",
            imgsrc1:side4,
            internal: ['Accurate daily, monthly, and lifetime well production forecasts', 'Analysis of product flow, demand, and price to make long-term capital decisions', 'optimize processing and refining processes', 'AI Led Inventory Management', 'Helpdesk, with voice bots for workers both on-field and in the office to Offer real-time solutions','Ease the learning curve for new hires'],
            external: ['24x7 FAQ Customer support ', 'First Line IT support to the customers', 'Conduct surveys  to collect customer feedback and recommendation', 'Provide product information to the customers'],
            percent:['45% Production and Maintenance Cost reduction by Leveraging AI and Analytics to boost data-driven decision-making', 'lorem', '30% reduction in operational costs by automating almost all the routine tasks, hence reducing human errors '],
            download: download4
        },
        {
            id:5,
            imgsrc:icon5,
            title:"Retail & E-Commerce",
            imgsrc1:side5,
            internal: ['Supply chain transparency', 'Inventory management','Advertise Facebook messenger, whatsapp, Instagram , etc','Upsell products, Convert lead to contact','Rescuing abandoned carts','Conduct market research','Monitor user sentiment','HR BOT'],
            external: ['24x7 Customer Support', 'Order placement, modification & tracking', 'Personalise shopping', 'Customer Loyalty Rewards & programs', 'In-chat promotions and coupons','Locate nearby stores'],
            percent:['Save 30% on customer support costs', 'Chatbots are most commonly used for sales (41%), customer support (37%), and marketing (17%).', 'The retail (35%) industries have the most popular use cases for voice assistants. (Capgemini, 2018)'],
            download: download5
        },
        {
            id:6,
            imgsrc:icon6,
            title:"Government & PSU",
            imgsrc1:side6,
            internal: ['Integrated city operation', 'Civic Engagement', 'Public Health', 'Housing Service', 'Process Automation','tax filing forms','deadlines','Transit information'],
            external: ['Payment of challans, local taxes, bills, etc', 'Getting information about various public services', 'Know about government procedures, legal advises, laws and acts, etc.', 'Complain redressal system'],
            percent:['$0.50-70 reduction in governance cost per G2C interaction', '40% user prefer to interact with bot and get answers quickly', '25% increase efficiency in process of addressal system'],
            download: download6
        },
        {
            id:7,
            imgsrc:icon7,
            title:"Aviation",
            imgsrc1:side7,
            internal: ['Process automation', 'Manage fuel efficiency to flight planning', 'Manpower Planning', 'Crew Rostering', 'Demand Forecasting','Multi Tool Integrated Dashboard','Unified View for Database Analysis','Easy Cyber Threat Analysis from Multiple Security Device'],
            external: ['Ticket Booking & Cancellation', 'Travel Advice', 'Deals & Promotions', 'Memberships & Loyalty programs', 'Activity Notification','Real Time Support','Automated Customer Service'],
            percent:['60% reduction in Call centre cost by automating customer support requests', '50% boost in revenue by selling ancillary services through bots'],
            download: download7
        },
        {
            id:8,
            imgsrc:icon8,
            title:"Food & Beverage",
            imgsrc1:side8,
            internal: ['Production Optimisation & Waste Reduction', 'Inventory and pricing analytics', 'Food Sorting & Quality check made easy', 'Sales Cycle Analysis', 'Constant product innovation with analytics customer emotions','HR Bots'],
            external: ['Manage Table reservations', 'Take orders right with special requests', 'In-chat promotions and coupons', 'Collect payments and send receipts', 'Marketing campaigns through FB, WhatsApp','Customer Loyalty Rewards & programs'],
            percent:['75% reduction in food wastage with Inventory optimisation', 'Upto 90% of the Order Inaccuracy can be removed through Intelligent Bots', '65% uplift in sales revenue output through omnichannel customer engagement', 'The food (56%) industries have the most popular use cases for voice assistants. (Capgemini, 2018)'],
            download: download8
        },
        {
            id:9,
            imgsrc:icon9,
            title:"IT & Engineering",
            imgsrc1:side9,
            internal: ['Scalable AI-based Automated Cloud Operations', 'Power & workload Analysis', 'Easy Cyber Threat Analysis from Multiple Security Device', 'AI-Driven Auto Remediation  & Fulfillment', 'AI For Patch Automation','AI-Driven Governance','AI-Based Capacity Management','Application Performance Analytics.'],
            external: ['Analytics report/ dashboard for 3rd party vendors to devise optimization strategy', 'Multi-vendor coordinated conversation', 'Notify respected vendor about any state change in the device through whatsapp notification', 'Help report stats and status generation of report for respective OEM / vendor'],
            percent:['60% Faster Decision Making', '40% Cost Savings', '50% Bandwidth for Innovation'],
            download: download9
        },
        {
            id:10,
            imgsrc:icon10,
            title:"Manufacturing",
            imgsrc1:side10,
            internal: ['Supplies and inventory check', 'Handling floor queries', 'Updates and delivery notifications', 'Operation Automation', 'Improving organization efficiency through process optimization AI tool','Easy access to knowledge and databases','Perform HR-related tasks'],
            external: ['Analytics report/ dashboard for 3rd party vendors', 'Multi-vendor coordinated conversation', 'Product recommendation', 'Handling customer service efficiently', 'Providing an interactive platform for vendors and customers','Market content through online channels'],
            percent:['60% Faster Decision Making', '40% Cost Savings', '30% Better Customer Service'],
            download: download10
        },
        {
            id:11,
            imgsrc:icon11,
            title:"Data Centres",
            imgsrc1:side11,
            internal: ['Cooling and predictive maintenance', 'Power & workload Analysis', 'L1 & L2 resource cost reduction', 'Easy Cyber Threat Analysis from Multiple Security Device'],
            external: ['Analytics report/ dashboard for 3rd party vendors to devise optimization strateg', 'Multi-vendor coordinated conversation', 'Notify respected vendor about any state change in the device through whatsapp notification', 'Help report stats and status generation of report for respective OEM / vendor'],
            percent:['60% Faster Decision Making', '40% Cost Savings'],
            download: download11
        },
        {
            id:12,
            imgsrc:icon12,
            title:"Healthcare",
            imgsrc1:side12,
            internal: ['Simplify complex processes and knowledge management tools', 'Automate administrative workstreams, so agents can focus on delivering better patient services', 'Automate insurance claim processing and healthcare billing', 'Integrate & simplifiy internal processes', 'Unified View for Database Analysis','Easy Cyber Threat Analysis from Multiple Security Device'],
            external: ['24x7 FAQ BOT', '24X7 access to medical knowledge bank', 'Illness Symptoms Checker', 'Schedule medical appointments', 'Guide members and patients on preventive care services','Improve first call resolution (FCR) rates and increasing Net Promoter Score (NPS)','Conduct surveys  to collect patient feedback and recommendation'],
            percent:['25% cost saving by flagging potential billing issues during interactions', '60% reduction in Call centre cost by automating customer support requests', '40% increase in sales through Omni Channel presence'],
            download: download12
        },
    ]
}
export default data;