import React from 'react'
import Commoncard from './Commoncard'
import chatbot1 from "../images/ChatBot_Icon.png";
import chatbot2 from "../images/VoiceBot_Icon.png";
import chatbot3 from "../images/Instant_Analytics_Icon.png";
const Framework = () => {
  return (
    <>
      <div className="framework py-5">
        <div className="container">
          <h1 className='main_heading'>iAssist NLP Framework</h1>
          <h4 className='sub_heading'>Unlock new possibilities, Boost productivity and Amplify your impact </h4>
          <div className="row pt-5 gx-md-5 gx-1">
            <div className="col-md-4 col-xxl-4 col-sm-4 col-12">
              <div class=" border-0  card-span shadow py-4 hover_effect h-100">
                <Commoncard imgsrc={chatbot1} title="Conversational AI" desc="Deliver an intelligent & humanlike experience to your users." />
                <div class="overlay ">
                  <h4 className='text-uppercase'>Conversational AI</h4>
                  <ul className='list_style list pb-2'>
                    <li>Customisable & AI Driven</li>
                    <li>Omni Channel</li>
                    <li>Multi Lingual</li>
                    <li>Interruptible & Contextual Conversations</li>
                    <li>Intent Recognition</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xxl-4 col-sm-4 col-12">
              <div class=" border-0  card-span shadow py-4 hover_effect h-100">
                <Commoncard imgsrc={chatbot2} title="VOICE BOT" desc="Ensure personalised Support to every customer 24x7" />
                <div class="overlay ">
                  <h4 className='text-uppercase'>Voice BOT</h4>
                  <ul className='list list_style pb-2'>
                    <li>Personalised Engagement</li>
                    <li>Omni Channel</li>
                    <li>Multi Lingual</li>
                    <li>Derive Customer behaviour patterns</li>
                    <li>100% customer satisfaction guaranteed</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xxl-4 col-sm-4 col-12 ">
              <div class=" border-0  card-span shadow py-4 hover_effect h-100">
                <Commoncard imgsrc={chatbot3} title="INSTANT BI & ANALYTICS" desc="Get granular business insights
on-the-go." />
                <div class="overlay ">
                  <h4 className='text-uppercase'>INSTANT BI & ANALYTICS</h4>
                  <ul className='list list_style pb-2'>
                    <li>Convert Static KPI to Dynamic KPI Dashboard</li>
                    <li>Generate Simple natural language to Complex SQL Query on the fly</li>
                    <li>Generate forecasts</li>
                    <li>Analyse root causes</li>
                    <li>Data Visualisation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Framework