import React from 'react';


const Commontelecom = (props) => {
  return (
    <>
    <div className="tele_bg bg-light py-5">
        <div className="container">
      <div className="row">
        <div className="col-md-8">
      <h1 class="fw-600">{props.maintitle}</h1>
      </div>
      </div>
      <div className="row py-5">
        <div className="col-md-4">
            <img src={props.img1} alt="img" className='img-fluid col-8 mx-auto' />
        </div>
        <div className="col-md-8">
        <h1 class="fw-600 pb-3">{props.title1}</h1>
        <div className="row">
            <div className="col-md-6">
                <h4>Offering</h4>
                <ul className='list-unstyled'>
                    <li>{props.list1}</li>
                    <li>{props.list2}</li>
                    <li>{props.list3}</li>
                    <li>{props.list4}</li>
                </ul>
            </div>
            <div className="col-md-6">
                <h4>Solution</h4>
                <ul className='list-unstyled'>
                  <li>{props.casepara1}</li>
                  <li>{props.casepara2}</li>
                  <li>{props.casepara3}</li>
                
                </ul>

                </div>
        </div>
        </div>
      </div>
      <div className="row py-5">
       
        <div className="col-md-8">
        <h1 class="fw-600 pb-3">{props.title2}</h1>
        <div className="row">
            <div className="col-md-6">
                <h4>Offering</h4>
                <ul className='list-unstyled'>
                    <li>{props.list11}</li>
                    <li>{props.list12}</li>
                    <li>{props.list13}</li>
                    <li>{props.list14}</li>
                </ul>
            </div>
            <div className="col-md-6">
                <h4>Solution</h4>
                <ul className='list-unstyled'>
                  <li>{props.casepara11}</li>
                  <li>{props.casepara12}</li>
                  <li>{props.casepara13}</li>
                </ul>
                </div>
        </div>
        </div>
        <div className="col-md-4">
        <img src={props.img2} alt="img" className='img-fluid col-8 mx-auto' />
        </div>
      </div>
      <div className="row py-5">
        <div className="col-md-4">
        <img src={props.img3} alt="img" className='img-fluid col-8 mx-auto' />
        </div>
        <div className="col-md-8">
        <h1 class="fw-600 pb-3">{props.title12}</h1>
        <div className="row">
            <div className="col-md-6">
                <h4>Offering</h4>
                <ul className='list-unstyled'>
                    <li>{props.list21}</li>
                    <li>{props.list22}</li>
                    <li>{props.list23}</li>
                    <li>{props.list24}</li>
                </ul>
            </div>
            <div className="col-md-6">
                <h4>Solution</h4>
                <ul className='list-unstyled'>
                  <li>{props.casepara21}</li>
                  <li>{props.casepara22}</li>
                 
                </ul>
 
                </div>
        </div>
        </div>
      </div>
    
      </div>
      </div>
    </>
  );
}

export default Commontelecom;
