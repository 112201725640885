import React from 'react';
import Commonapp from './Commonvoicecase';
import Appdata from './Conversationcasedata'
const Conversationcase = (props) => {
  return (
    <>
      <div className="voicecase_area  py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-12 text-white">
                            <h1 className='main_heading'>{props.title}</h1>
                           
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="row pt-5 gx-md-5 gx-1">

                                {
                                    Appdata.map((val, ind) => {
                                        return (
                                            <Commonapp key={ind} iconimg={val.voicecaseimg} title={val.voicecasetitle} />
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}

export default Conversationcase;
