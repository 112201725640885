import React from 'react';

const Commonfeature = (props) => {
  return (
    <>
      <div className="col-md-3 col-xxl-3 col-sm-3 col-6 text-center">
        <div className="box ">
       <img src={props.iconimg} alt="img"  className="img-fluid col-md-4 col-6"/>
       <h5 className='py-4 text-capitalize'>{props.title}</h5>
       </div>
      </div>
    </>
  );
}

export default Commonfeature;
