import React from 'react';
import { NavLink } from 'react-router-dom';
const Consultblock = () => {
    return (
        <>
            <div className="consult_area py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 ">
                            <h1>Enough talk, let’s build AI
                                together. Get a consultation.</h1>
                            <NavLink to="/Contact" className=" text-uppercase mt-3 btn_style"  >Consult Now</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Consultblock;
