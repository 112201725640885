import React from 'react';
import Breadcrumsec from '../../components/Breadcrumsec';
import { NavLink } from 'react-router-dom';
import Consultblock from '../../components/Consultblock';

import Footer from '../../components/Footer';
import Headerin from '../../components/Headerin';
import Internalcommon from '../../components/Internalcommon';
import Internalsection from '../../components/Internalsection';
import serviceimg from '../../images/aviation/side.png'
import voicebotimg from '../../images/solutionside/Aviation_Rigth_img.png'
import Commonbox from '../../components/Commonbox';
import Commontelecom from '../../components/Commontelecom';
import img1 from '../../images/solution/Telecom_Support.png'
import img2 from '../../images/solution/Telecom_Reset.png'
import img3 from '../../images/solution/Telecom_Tariff.png'
const Aviation = () => {
  return (
    <>
      <Headerin />
      <div className="Aviation_area">
        <Internalcommon title="Aviation" desc="Reforming Aviation with Conversational AI" imgsrc={serviceimg} />
      </div>
      <Breadcrumsec maintitle="Home" categorytitle="Solution" subtitle="Aviation" />
      <Internalsection title="
iAssist : Aviation" span_heading="iAssist is a Made-in-India holistic NLP framework for AI enabled Virtual Assistants that delivers" para1="A digital-first, human-like conversational experience through voice and text interactions with customers, service providers, agents, employees and consumers across the enterprise." para2="The explainable AI platform and machine learning capabilities are embedded in the iAssist Platform and available either through an easy-to-use interface or through APIs delivered on-premise, in the cloud or as a SaaS offering." imgsrc={voicebotimg} />
      <div className="container">
        <div className="row ">
          <div className="col-md-3 ">
            <NavLink to="/Casestudies/Assisto_Aviation.pdf" target="_blank" download className="py-2 btn btn-dark  text-uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z"></path>
                <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"></path>
              </svg>
              &nbsp; Click to Download
            </NavLink>

          </div>
        
        </div>
      </div>
      <div className="container">
      <Commonbox  value1="50%" value2="60%" value3="25%" text1="boost in revenue by selling ancillary services through bots" text2="call center cost reduction by automating customer support requests" text3="cost reduction by optimising total fuel usage" />

      </div>
      <Commontelecom maintitle="IAssist Use Cases" 
          img1={img1} 
          title1="Ticket Booking & Modification" 
          list1="- Conversational AI" 
          casepara1="&bull; Conversational ai will get all the essential user information " 
          casepara2="&bull; Respond to the customer queries humanlike even if asked with different orientations"
         
          title2="Travel Advice" 
          list11="- Conversational AI " 
          list12="- Instant Analytics "
          casepara11="&bull; The virtual assistant through conversational AI collects details and displays offers or advices on travel based on collected details 
          "
         
          img2={img2}

          img3={img3} 
          title12="Demand Forecasting " 
          list21="- Conversational AI" list22="- Instant Analytics" 
          casepara21="&bull; Take key parameters from users and connect to database render data in tabular or chart format if data through API of a tool" 
          casepara22="&bull; In case, demand data is in database, use NL2SQL to filter and fetch data to be rendered in tabular or chart format"
          />
          <div className="container">
            <div className="row py-5">
       
       <div className="col-md-8">
       <h1 class="fw-600 pb-3">Loyalty Program Management
</h1>
       <div className="row">
           <div className="col-md-6">
               <h4>Offering</h4>
               <ul className='list-unstyled'>
                   <li>- Conversational AI</li>
                   <li>- ASR</li>
                   <li>- Voice Authentication</li>
                   <li>- Face recognition</li>
               </ul>
           </div>
           <div className="col-md-6">
               <h4>Solution</h4>
   <ul className='list-unstyled'>
    <li>&bull; Biometric account Login</li>
    <li>&bull; Suggest user their current membership Tier status and requirements to upgrade the same  </li>
    <li>&bull; Offers and discounts available on their memberships, their date of expiry, etc</li>
   </ul>
               </div>
       </div>
       </div>
       <div className="col-md-4">
       <img src={img1} alt="img" className='img-fluid col-8 mx-auto' />
       </div>
     </div>
     </div>
      <Consultblock />

      <Footer />

    </>
  );
}

export default Aviation;
